import React, { useEffect, useState } from 'react';

import {
  AuthContextProps,
  Role,
  useAuth,
} from '../../providers/auth-provider/auth-provider';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { loadSettings } from '../../store/settings';
import {
  SettingsState,
  SettingType,
  SystemSettings,
} from '../../store/settings/types';
import ErrorList from '../../stories/error-list/error-list';
import SettingsCategory from '../../stories/settings-category/settings-category';
import Status from '../../stories/status/status';
import UserList from '../../stories/user-list/user-list';

export type SettingsPageProps = {
  authContext?: React.Context<AuthContextProps>;
};

export const SettingsPage: React.FC<SettingsPageProps> = ({
  authContext,
}: SettingsPageProps) => {
  const countOvens: number = useAppSelector(
    (state: { settings: SettingsState }) => {
      const ovenCountSetting = state.settings.settings.find(
        (setting) => setting.name === SystemSettings.ovens,
      );

      if (!ovenCountSetting) {
        return 0;
      }

      return ovenCountSetting.valueWithType.value as number;
    },
  );

  const liveViewSettings = useAppSelector(
    (state: { settings: SettingsState }) => {
      if (!state.settings.settings) {
        return [];
      }
      return state.settings.settingsByCategory['live-view'];
    },
  );

  /// Prepare Settings for each oven
  const ovenConfigSettings: { [key: string]: SettingType[] } = useAppSelector(
    (state: { settings: SettingsState }) => {
      const nextOvenSettings: { [key: string]: SettingType[] } = {};
      if (!state.settings.settingsByCategory || !countOvens) {
        return nextOvenSettings;
      }

      for (let i = 0; i < countOvens; i++) {
        const ovenKey = `oven-config-${i}`;
        nextOvenSettings[ovenKey] = state.settings.settingsByCategory[ovenKey];
      }

      return nextOvenSettings;
    },
  );

  const systemSettings = useAppSelector(
    (state: { settings: SettingsState }) => {
      if (
        !state.settings.settingsByCategory ||
        Object.keys(state.settings.settingsByCategory).length === 0
      ) {
        return [];
      }
      const systemConfig =
        state.settings.settingsByCategory['system-config'] || [];
      const systemGui = state.settings.settingsByCategory['gui-system'] || [];

      return [...systemConfig, ...systemGui];
    },
  );

  const ovenGuiSettings: { [key: string]: SettingType[] } = useAppSelector(
    (state: { settings: SettingsState }) => {
      const nextOvenSettings: { [key: string]: SettingType[] } = {};
      if (!state.settings.settingsByCategory || !countOvens) {
        return nextOvenSettings;
      }

      for (let i = 0; i < countOvens; i++) {
        const ovenKey = `gui-oven-${i}`;
        nextOvenSettings[ovenKey] = state.settings.settingsByCategory[ovenKey];
      }

      return nextOvenSettings;
    },
  );

  const [isServiceRole, setIsServiceRole] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { hasRole } = authContext ? React.useContext(authContext) : useAuth();

  // Trigger Load Settings
  useEffect(() => {
    dispatch(loadSettings());
    hasRole([Role.service]).then((hasIt) => setIsServiceRole(hasIt));
  }, []);

  return (
    <div className="settings-page">
      <div className="fs-2 pt-2 pb-4">Settings</div>
      <div className="pb-4">
        <SettingsCategory
          title={'Live View Settings'}
          settings={liveViewSettings}
          readonly={false}
        />
      </div>
      <div className={'row'}>
        {Object.keys(ovenConfigSettings).map((ovenSettingKey, index) => (
          <div className="col-6 pb-4" key={ovenSettingKey}>
            <SettingsCategory
              ovenIndex={index}
              title={`Ofen ${index + 1}`}
              settings={ovenConfigSettings[ovenSettingKey]}
              readonly={false}
            />
          </div>
        ))}
      </div>

      <div className={'row'}>
        <div className={'col-12'}>
          <ErrorList object={'oven'} object_number={[0, 1]} />
        </div>
      </div>

      {isServiceRole && (
        <>
          <div className="pt-5 pb-4">
            <hr />
          </div>
          <div className="fs-2">Service</div>
          <div className="pb-4">
            <SettingsCategory
              title={`Allgemein`}
              settings={systemSettings}
              readonly={false}
            />
          </div>

          <div className={'row'}>
            {Object.keys(ovenGuiSettings).map((ovenSettingKey, index) => (
              <div className="col-6 pb-4" key={ovenSettingKey}>
                <SettingsCategory
                  title={`Ofen ${index + 1}`}
                  settings={ovenGuiSettings[ovenSettingKey]}
                  readonly={false}
                />
              </div>
            ))}
          </div>

          <div className={'row'}>
            <div className={'col-6'}>
              <Status name={`relay-${0}`} />
            </div>
            <div className={'col-6'}>
              <Status name={`relay-${1}`} />
            </div>
          </div>

          <div className="pt-5 pb-4">
            <hr />
          </div>

          <div className={'row'}>
            <div className={'col-12'}>
              <ErrorList object={'relay'} object_number={[0, 1]} />
            </div>
          </div>

          <div className="pt-5 pb-4">
            <hr />
          </div>

          <UserList />
        </>
      )}
    </div>
  );
};
