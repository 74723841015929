import React from 'react';

import useCanvas from '../../helpers/use-canvas';

export type EwsCanvasProps = {
  draw: (ctx: CanvasRenderingContext2D, height?: number) => void;
  height?: number;
  width?: number;
};

export default function EwsCanvas({ draw, height }: EwsCanvasProps) {
  const canvasRef = useCanvas(draw, { height });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <canvas ref={canvasRef} />;
}
