import './_error-list.scss';

import { useParseQuery } from '@parse/react';
import { timeFormat } from 'd3-time-format';
import React from 'react';
import { Button, Card } from 'react-bootstrap';

import { errorHeadlines } from '../error-messages/error-item';

export type ErrorListProps = { object: string; object_number: number[] };

export default function ErrorList({ object }: ErrorListProps) {
  const query = new Parse.Query('Error')
    .equalTo('object', object)
    .descending('occurred_on')
    .limit(10);

  const { results } = useParseQuery(query, {
    enableLiveQuery: true,
  });
  const formatTimeAndDate = timeFormat('%d.%m.%y %H:%M Uhr');
  const label = object === 'relay' ? 'Relais' : 'Ofen';

  const onAcknowledge = (error: Parse.Object) => {
    error.set('acknowledged_on', new Date(Date.now()));
    error.set('acknowledged_by', Parse.User.current());
    error.set('hidden_on', new Date(Date.now()));
    error.set('hidden_by', Parse.User.current());
    error.save();
  };

  const renderStatus = (error: Parse.Object) => {
    const solved = !!error.attributes.solved_on;
    const acknowledged = !!error.attributes.acknowledged_on;

    if (solved) {
      return (
        <div className={'p-1 background-green rounded text-center'}>
          behoben
        </div>
      );
    }

    if (acknowledged) {
      return (
        <div className={'p-1 background-yellow rounded text-center'}>
          quittiert
        </div>
      );
    }

    return (
      <div className={'p-1 background-alert rounded text-center'}>
        Fehler aktiv
      </div>
    );
  };

  return (
    <div className="error-list">
      <div className={'fs-4'}>Letzte Fehler {label}</div>
      <Card className={'shadow p-4 pt-0'}>
        {!results ||
          (results.length === 0 && (
            <div className={'row pt-4 pb-4 border-bottom'}>
              <div className={'col-12 text-center'}>Keine Fehler</div>
            </div>
          ))}
        {results && results?.length > 0 && (
          <div className={'row pt-4 pb-4 border-bottom'}>
            <div className={'col-2 fw-bold'}>{label}</div>
            <div className={'col-3 fw-bold'}>Fehler</div>
            <div className={'col-1 fw-bold'}>Aufgetreten</div>
            <div className={'col-1 fw-bold'}>Gelöst</div>
            <div className={'col-1 fw-bold'}>Quittiert</div>
            <div className={'col-2 fw-bold'}>Quittiert von</div>
            <div className={'col-1 fw-bold'}>Status</div>
            <div className={'col-1 fw-bold'}>Aktionen</div>
          </div>
        )}
        {results &&
          results.length > 0 &&
          results.map((error: Parse.Object) => (
            <div key={error.id} className={'row pt-2 pb-2 border-bottom'}>
              <div className={'col-2'}>{error.attributes.label}</div>
              <div className={'col-3'}>
                {errorHeadlines[error.attributes.message as string]}
              </div>
              <div className={'col-1'}>
                {formatTimeAndDate(
                  new Date(Date.parse(error.attributes.occurred_on)),
                )}
              </div>
              <div className={'col-1'}>
                {error.attributes.solved_on &&
                  formatTimeAndDate(
                    new Date(Date.parse(error.attributes.solved_on)),
                  )}
              </div>
              <div className={'col-1'}>
                {error.attributes.acknowledged_on &&
                  formatTimeAndDate(
                    new Date(Date.parse(error.attributes.acknowledged_on)),
                  )}
              </div>
              <div className={'col-2'}>
                {error.attributes.acknowledged_by &&
                  error.attributes.acknowledged_by.getUsername()}
              </div>
              <div className={'col-1 text-center justify-content-center'}>
                {renderStatus(error)}
              </div>
              <div className={'col-1'}>
                <Button
                  disabled={!!error.attributes.acknowledged_on}
                  onClick={() => onAcknowledge(error)}
                >
                  Fehler quittieren
                </Button>
              </div>
            </div>
          ))}
      </Card>
    </div>
  );
}
