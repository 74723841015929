import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import EwsCanvas from '../ews-canvas/ews-canvas';

// eslint-disable-next-line @typescript-eslint/ban-types
export type EkgLineProps = {
  height: number;
  current: number;
  groundWarning: unknown;
  groundCritical: unknown;
};

// eslint-disable-next-line no-empty-pattern
export default function EkgLine({
  height,
  current,
  groundWarning,
  groundCritical,
}: EkgLineProps) {
  if (typeof groundWarning === 'string') {
    groundWarning = Number.parseInt(groundWarning);
  }

  if (typeof groundCritical === 'string') {
    groundCritical = Number.parseInt(groundCritical);
  }

  const ground_limit_warning: number = groundWarning as number;
  const ground_limit_critical: number = groundCritical as number;

  const MAX_LEVEL = 0.8;
  const NOISE = 0.05;
  const CURVE_COLOR = '#0029B4';
  const PLOT_GAP = 5;
  const SAMPLE_POINTS = 300;
  const SAMPLE_INTERVAL = 50; //30; // Noise Data Interval
  const BEAT_INTERVAL = 1500;
  let PULSE = false;

  const ref = useRef<HTMLDivElement>(null);
  const beatLevel = useRef<number>(1);
  const heartIndexRef = useRef<number>(0);
  const heartDataRef = useRef<number[]>([0, 0, 0, 0, 0]);
  const beatDataIndex = useRef<number>(-1);

  const [width, setWidth] = useState(0);
  // const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    if (ref?.current?.parentElement?.offsetWidth) {
      setWidth(ref.current.parentElement.offsetWidth);
    }
  }, [ref.current?.parentElement?.offsetWidth]);

  useEffect(() => {
    fillHeartData(SAMPLE_POINTS);
    const interval = setInterval(() => {
      updateData();
    }, SAMPLE_INTERVAL);

    const pulse_timer = setTimeout(function run() {
      PULSE = true;
      setTimeout(run, BEAT_INTERVAL);
    }, BEAT_INTERVAL);

    return () => {
      clearInterval(interval);
      clearTimeout(pulse_timer);
    };
  }, []);

  useEffect(() => {
    const limit_low = ground_limit_warning; //15;
    const limit_high = ground_limit_critical; //20;
    const ground_resistance = current;
    let level =
      -(100 / (limit_high - limit_low)) * ground_resistance +
      100 * (limit_low / (limit_high - limit_low) + 1);
    if (level < 0) {
      level = 0;
    } else if (level > 100) {
      level = 100;
    }
    level = level / 100;
    if (Number.isNaN(ground_resistance)) {
      level = 0;
    }
    beatLevel.current = level;
  }, [current]);

  const updateData = () => {
    heartIndexRef.current++;

    if (heartIndexRef.current >= heartDataRef.current.length) {
      heartIndexRef.current = 0;
    } else {
      //heartDataIndex++;
    }

    if (beatDataIndex.current >= 0 || PULSE) {
      fillBeatData();
      PULSE = false;
    } else {
      fillRandomData();
    }
  };

  const fillRandomData = () => {
    heartDataRef.current[heartIndexRef.current] =
      Math.random() * NOISE - NOISE / 2;
  };

  const fillHeartData = (length: number) => {
    if (length !== heartDataRef.current.length) {
      heartDataRef.current = new Array(length);
      for (let i = 0; i < length; i++) {
        heartDataRef.current[i] = Math.random() * NOISE - NOISE / 2;
      }
    }
  };

  const fillBeatData = () => {
    const getValue = function (idx: number) {
      let point = Math.random() * NOISE - NOISE / 2; // default noise
      const pulse_prototype = [
        0.1,
        0,
        0.66 * 0.95 * MAX_LEVEL,
        0.95 * MAX_LEVEL,
        0.95 * 0.95 * MAX_LEVEL,
        0.33 * 0.95 * MAX_LEVEL,
        -0.05,
        -0.66 * MAX_LEVEL,
        -MAX_LEVEL,
        -0.9 * MAX_LEVEL,
        -0.05,
        -0.05,
        0.15,
      ];
      if (idx in pulse_prototype) {
        //point = Math.random() * NOISE - NOISE/2 + pulse_prototype[idx] * Level;
        point =
          Math.random() * NOISE -
          NOISE / 2 +
          pulse_prototype[idx] * beatLevel.current;
      }
      return point;
    };
    heartDataRef.current[heartIndexRef.current] = getValue(
      beatDataIndex.current,
    );
    //last_sig = heartData[heartDataIndex]
    beatDataIndex.current++;
    if (beatDataIndex.current > 11) {
      beatDataIndex.current = -1;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ellipse = (ctx: any, x: number, y: number, a: number, b: number) => {
    ctx.save();
    ctx.beginPath();
    ctx.translate(x, y);
    ctx.scale(a / b, 1);
    ctx.arc(0, 0, b, 0, Math.PI * 2, true);
    ctx.restore();
    ctx.closePath();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const legend = (ctx: any) => {
    // StatusText
    const height = ctx.canvas.height;
    ctx.font = '12px Arial';
    ctx.fillStyle = 'black';
    // const status_text = 'Level=' + beatLevel.current;
    const upperLimitText = '1';
    const zeroLineText = '0';
    const lowerLimitText = '-1';
    // ctx.fillText(status_text, 5, height);
    ctx.fillText(upperLimitText, 3, 18);
    ctx.fillText(zeroLineText, 3, height / 2 + 4);
    ctx.fillText(lowerLimitText, 3, height - 12);
  };

  const draw = (ctx: CanvasRenderingContext2D) => {
    const leftPadding = 15;
    const width = ctx.canvas.width;
    const height = ctx.canvas.height;
    const baseY = height / 2;
    const length = heartDataRef.current.length;
    const step = (width - 4) / length;
    const yFactor = height * 0.5;
    let heartIndex = (heartIndexRef.current + 1) % length;
    ctx.clearRect(0, 0, width, height);

    // Background
    ctx.fillStyle = '#FFFFFF';
    //ctxRef.current.fillRect(0,0, canvasRef.current.width, canvasRef.current.height);
    ctx.fillRect(0, 0, width, height);

    //Border
    ctx.globalCompositeOperation = 'source-over';
    ctx.lineWidth = 0.25;
    ctx.strokeStyle = '#000000';
    ctx.strokeRect(0, 0, width, height);

    // Skala
    ctx.lineWidth = 0.25;
    ctx.strokeStyle = '#000000';

    // Nulllinie
    ctx.strokeRect(leftPadding, baseY, width, 0);
    // 100%
    ctx.strokeRect(leftPadding, baseY + MAX_LEVEL * yFactor, width, 0);
    ctx.strokeRect(leftPadding, baseY - MAX_LEVEL * yFactor, width, 0);
    // 75%
    ctx.strokeRect(leftPadding, baseY + MAX_LEVEL * yFactor * 0.75, width, 0);
    ctx.strokeRect(leftPadding, baseY - MAX_LEVEL * yFactor * 0.75, width, 0);
    // 50%
    ctx.strokeRect(leftPadding, baseY + MAX_LEVEL * yFactor * 0.5, width, 0);
    ctx.strokeRect(leftPadding, baseY - MAX_LEVEL * yFactor * 0.5, width, 0);
    // 25%
    ctx.strokeRect(leftPadding, baseY + MAX_LEVEL * yFactor * 0.25, width, 0);
    ctx.strokeRect(leftPadding, baseY - MAX_LEVEL * yFactor * 0.25, width, 0);

    // Signal
    const cursor_index = heartIndexRef.current % length;
    const cursor_x = cursor_index * step + leftPadding;
    const cursor_y = baseY - heartDataRef.current[cursor_index] * yFactor;
    let start_x = 0;
    let start_y = baseY;

    ctx.lineWidth = 3;
    ctx.strokeStyle = CURVE_COLOR;

    ctx.beginPath();

    if (cursor_index < (cursor_index + PLOT_GAP) % length) {
      start_x = leftPadding + 2;
      start_y = baseY - heartDataRef.current[start_x] * yFactor;
    } else {
      start_x = leftPadding + 2 + ((cursor_index + PLOT_GAP) % length);
      start_y =
        baseY -
        heartDataRef.current[(cursor_index + PLOT_GAP) % length] * yFactor;
    }

    ctx.moveTo(start_x * step, start_y);
    let i = start_x,
      x = 0,
      y = 0;
    for (i = start_x + 1; i < length; i++) {
      x = leftPadding + 2 + i * step;
      y = baseY - heartDataRef.current[i] * yFactor;
      if (
        i === heartIndexRef.current &&
        heartIndexRef.current + PLOT_GAP < length
      ) {
        ctx.lineTo(x, y);
        ctx.stroke();
        ctx.closePath();
      } else if (i === heartIndexRef.current + PLOT_GAP) {
        ctx.beginPath();
        ctx.moveTo(x, y);
      } else if (i > cursor_index && i < cursor_index + PLOT_GAP) {
        //pass
      } else {
        ctx.lineTo(x, y);
      }
      heartIndex = (heartIndex + 1) % length;
    }
    ctx.stroke();
    ctx.closePath();

    // running dot:
    ctx.beginPath();
    ctx.fillStyle = CURVE_COLOR;
    const dot_size = 3;
    ellipse(
      ctx,
      cursor_x - dot_size / 2,
      cursor_y - dot_size / 2,
      dot_size,
      dot_size,
    );
    ctx.fill();
    ctx.stroke();
    ctx.closePath();

    legend(ctx);
  };

  return (
    <div ref={ref} className={'ekg-line'}>
      <EwsCanvas draw={draw} width={width} height={height} />
    </div>
  );
}
