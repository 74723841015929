import { useParseQuery } from '@parse/react';
import React from 'react';
import { Card } from 'react-bootstrap';
import { GoDot } from 'react-icons/go';

import { useParse } from '../../providers/parse-provider/parse-provider';

export type StatusProps = {
  name: string;
};

export default function Status({ name }: StatusProps) {
  const Parse = useParse();

  const liveQuery = new Parse.Query('Status').equalTo('object', name);

  const { results } = useParseQuery(liveQuery, { enableLiveQuery: true });

  const label = (name: string): string => {
    const num = Number.parseInt(name.charAt(name.length - 1)) + 1;
    return name.charAt(0).toUpperCase() + name.slice(1, -2) + ' ' + num;
  };

  const renderStatus = (label: string, value: boolean) => (
    <>
      <div className={'row'}>
        <div className={'col-12 fs-caption text-center'}>{label}</div>
      </div>
      <div className={'row'}>
        <div className={'col-12 text-center'}>
          <GoDot
            color={value ? 'red' : 'green'}
            size={40}
            className={'glow'}
          ></GoDot>
        </div>
      </div>
    </>
  );

  return (
    <div className="status">
      <div className={'fs-4'}>Status {label(name)}</div>
      <Card className={'p-2 shadow'}>
        {results &&
          results.map((result) => (
            <div className={'row'} key={result.id}>
              <div className={'col-4 border-right'}>
                {renderStatus(
                  'Kontaktierung',
                  result.attributes['ground_advance'],
                )}
              </div>
              <div className={'col-4 border-right'}>
                {renderStatus(
                  'Wear Warnung',
                  result.attributes['melt_advance'],
                )}
              </div>
              <div className={'col-4 border-right'}>
                {renderStatus(
                  'Wear kritisch',
                  result.attributes['melt_critical'],
                )}
              </div>
            </div>
          ))}
      </Card>
    </div>
  );
}
