import React from 'react';

export enum Role {
  'live' = 'live',
  'analyse' = 'analyse',
  'settings' = 'settings',
  'service' = 'service',
}

// Properties for our AuthContext
export interface AuthContextProps {
  user: Parse.User<Parse.Attributes> | undefined | null;
  hasRole: (roles: Role[]) => Promise<boolean>;
  onLogin: ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => Promise<void>;
  onLogout: () => void;
  sessionLogin: ({ token }: { token: string }) => Promise<void>;
}

// Initialize props with null values
export const authContextProps: AuthContextProps = {
  user: undefined,
  hasRole: () => new Promise<boolean>(() => false),
  onLogin: () =>
    new Promise<void>((resolve) => {
      resolve();
    }),
  onLogout: () => null,
  sessionLogin: () =>
    new Promise<void>((resolve) => {
      resolve();
    }),
};

// The AuthContext used in the app.
export const AuthContext = React.createContext(authContextProps);

// Our authentication method, which authenticates against the Parse Server
const authenticate = async ({
  username,
  password,
}: {
  username: string;
  password: string;
}): Promise<Parse.User<Parse.Attributes>> => {
  return await Parse.User.logIn(username, password);
};

// Our authentication object. Can be called from anywhere in the app without the need for passing AuthContextProps down the component tree.
export const useAuth = () => {
  return React.useContext(AuthContext);
};

// The Auth Provider, which handles the state of the authentication and provides methods for login and logout.
export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  // As initial user state we set the currently authenticated user.
  const [user, setUser] = React.useState<
    Parse.User<Parse.Attributes> | undefined
  >(Parse.User.current());

  // Login to parse.
  const handleLogin = async ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => {
    const user = await authenticate({ username, password });
    setUser(user);
  };

  const sessionLogin = async ({ token }: { token: string }) => {
    try {
      const user = await Parse.User.become(token);
      setUser(user);
    } catch (e) {
      setUser(undefined);
      window.localStorage.clear();
      alert('Session Token ist ungültig.');
    }
  };

  // Logout of parse.
  const handleLogout = () => {
    Parse.User.logOut().then(() => {
      setUser(undefined);
      window.localStorage.clear();
    });
  };

  const hasRole = async (roles: Role[]): Promise<boolean> => {
    if (!user) {
      return false;
    }

    try {
      const query = new Parse.Query(Parse.Role);
      query.containedIn(
        'name',
        roles.map((role) => role.valueOf()),
      );
      query.equalTo('users', user);
      const result = await query.find();

      return result.length > 0;
    } catch (e) {
      Parse.User.logOut();
    }

    return false;
  };

  const value: AuthContextProps = {
    user,
    hasRole,
    onLogin: handleLogin,
    onLogout: handleLogout,
    sessionLogin,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
