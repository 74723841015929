import { isNumber } from 'lodash';
import React, { useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import { SettingType, SettingValueType } from '../../store/settings/types';

export type SettingInputProps = {
  setting: SettingType;
  readonly: boolean;
  onChange: (arg0: SettingType) => void;
};

export default function SettingInput({
  setting,
  readonly,
  onChange,
}: SettingInputProps) {
  const [_setting, setSetting] = useState<SettingType>(setting);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onLocalChange = (event: any) => {
    let nextValue = undefined;
    if (Object.keys(event.target as HTMLElement).includes('checked')) {
      nextValue = event.target.checked;
    } else {
      nextValue =
        event.target.target || event.target.value || event.target.text;
    }

    if (nextValue === undefined || nextValue === null) {
      nextValue = '';
    }

    if (
      typeof _setting.valueWithType.value === 'string' &&
      (_setting.valueWithType.value as string).includes(
        _setting.valueWithType.unit || '',
      )
    ) {
      (_setting.valueWithType.value as string)
        .replace(_setting.valueWithType.unit || '', '')
        .trim();
    }

    const nextSetting = Object.assign({}, _setting, {
      valueWithType: {
        ..._setting.valueWithType,
        value: nextValue,
      },
    });

    setSetting(nextSetting);
    onChange(nextSetting);
  };

  const inputName = _setting.name + '_' + _setting.category;

  let _date = _setting.valueWithType.value as string;
  if (
    _setting.valueWithType.type === SettingValueType.date &&
    isNumber(_setting.valueWithType.value)
  ) {
    _date = new Date((_setting.valueWithType.value as number) * 1000)
      .toISOString()
      .substring(0, 10);
  }

  const formatNumberListValueWithUnit = ({
    value,
    unit,
  }: {
    value: unknown;
    unit?: string;
  }) => {
    if (!unit) {
      unit = '';
    }

    if (typeof value === 'string') {
      value = Number.parseInt(value);
    }

    if (unit === 'Minuten' && (value as number) > 59) {
      value = Math.round((((value as number) / 60) * 100) / 100);
      unit = (value as number) > 1 ? 'Stunden' : 'Stunde';
    }

    return `${value} ${unit}`;
  };

  switch (setting.valueWithType.type) {
    case SettingValueType.boolean:
      return (
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            readOnly={readonly}
            disabled={readonly}
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
            name={inputName}
            onChange={onLocalChange}
            checked={_setting.valueWithType.value as boolean}
          />
        </div>
      );
    case SettingValueType.date:
      return (
        <>
          <Form.Group controlId={`date-${_setting.name}`} className="w-50">
            <Form.Control
              type="date"
              name={`date-${_setting.name}`}
              defaultValue={_date}
              placeholder={_setting.label || _setting.name}
              onChange={onLocalChange}
              readOnly={readonly}
              disabled={readonly}
            />
          </Form.Group>
        </>
      );
    case SettingValueType.string:
      return (
        <input
          type="text"
          className="form-control w-50"
          readOnly={readonly}
          disabled={readonly}
          placeholder={_setting.label}
          aria-label={_setting.label}
          aria-describedby="basic-addon1"
          value={_setting.valueWithType.value as string}
          name={inputName}
          onChange={onLocalChange}
        />
      );
    case SettingValueType.stringList: {
      return (
        <Dropdown>
          <Dropdown.Toggle
            variant="secondary"
            id="dropdown-live-interval"
            disabled={readonly}
          >
            {_setting.valueWithType.value as string}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {(_setting.valueWithType.value as string)
              .split(',')
              .map((value) => (
                <Dropdown.Item
                  key={inputName + '_' + value}
                  onClick={onLocalChange}
                >
                  {value}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      );
    }
    case SettingValueType.number: {
      return (
        <InputGroup className="w-50">
          <Form.Control
            type="number"
            className="form-control"
            readOnly={readonly}
            disabled={readonly}
            placeholder={_setting.name}
            aria-label={_setting.name}
            aria-describedby="basic-addon1"
            value={_setting.valueWithType.value as number}
            name={inputName}
            onChange={onLocalChange}
          />
          {_setting.valueWithType.unit && (
            <InputGroup.Text id={`input-${setting.name}`}>
              {_setting.valueWithType.unit}
            </InputGroup.Text>
          )}
        </InputGroup>
      );
    }
    case SettingValueType.numberList: {
      return (
        <DropdownButton
          variant="secondary"
          id={`${setting.name}-toggle`}
          disabled={readonly}
          title={formatNumberListValueWithUnit({
            value: _setting.valueWithType.value as number,
            unit: _setting.valueWithType.unit,
          })}
        >
          {(_setting.options as number[]).map((value) => (
            <Dropdown.Item
              eventKey={value}
              key={inputName + '_' + value}
              onClick={onLocalChange}
            >
              {formatNumberListValueWithUnit({
                value,
                unit: _setting.valueWithType.unit || '',
              })}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      );
    }
    default:
      return <div className="setting-input">Noch nicht implementiert.</div>;
  }
}
