import './_error-messages.scss';

import { useParseQuery } from '@parse/react';
import Parse from 'parse';
import React from 'react';

import { useParse } from '../../providers/parse-provider/parse-provider';
import { useAppSelector } from '../../store/hooks';
import { selectSystemConfigValue } from '../../store/settings/selectors';
import { SettingsState } from '../../store/settings/types';
import { renderParseAlert } from './error-item';

const HOUR_MILLIS = 60 * 60 * 1000;
const RESHOW_HIDDEN_ERROR_MILLIS = 4 * HOUR_MILLIS;

export default function ErrorMessages() {
  const Parse = useParse();

  const liveQuery = new Parse.Query.or(
    new Parse.Query('Error').doesNotExist('solved_on'),
  );

  const { results } = useParseQuery(liveQuery, { enableLiveQuery: true });

  const onAcknowledge = (error: Parse.Object) => {
    error.set('acknowledged_on', new Date(Date.now()));
    error.set('acknowledged_by', Parse.User.current());
    error.save();
  };

  const onClose = (error: Parse.Object) => {
    error.set('hidden_on', new Date(Date.now()));
    error.set('hidden_by', Parse.User.current());
    error.save();
  };

  const showSystemErrors = useAppSelector(
    (state: { settings: SettingsState }) =>
      selectSystemConfigValue(state, 'show_system_error'),
  );

  if (!showSystemErrors) {
    return <></>;
  }

  const sortByCritical = (a: Parse.Object, b: Parse.Object) => {
    if (!a.get('message' || !b.get('message'))) {
      return 0;
    }
    if (
      a.get('message').includes('critical') &&
      !b.get('message').includes('critical')
    ) {
      return 1;
    }

    if (
      b.get('message').includes('critical') &&
      !a.get('message').includes('critical')
    ) {
      return -1;
    }

    return 0;
  };

  const filterHidden = (error: Parse.Object) => {
    const hiddenOn: Date = error.get('hidden_on');
    if (!hiddenOn) {
      return true;
    }
    return Date.now() - RESHOW_HIDDEN_ERROR_MILLIS > hiddenOn.valueOf();
  };

  return (
    <div className={'error-messages'}>
      {results
        ?.filter(filterHidden)
        .sort(sortByCritical)
        .map((error: Parse.Object, index) =>
          renderParseAlert(error, onAcknowledge, onClose, index),
        )}
    </div>
  );
}
