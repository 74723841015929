import { ParameterizedQuery, QueryApi } from '@influxdata/influxdb-client';
import React from 'react';

import { EWSError } from '../../store/error/types';
import { DisplayData, HOUR_SECONDS, WEEK_SECONDS } from './history';

// display query
export const influxQuery = ({
  ovens,
  measurements,
  fields,
  period = '1h',
  start,
  end,
  aggregation = 'median',
  bucket,
}: {
  ovens: number[];
  measurements: string[];
  fields: string[];
  period?: string;
  start: number;
  end?: number;
  aggregation?: string;
  bucket: string;
}): string => {
  if (!end) {
    end = Date.now();
  }

  if (!period || period === 'auto') {
    const duration = end - start;
    period = '1s';
    if (duration >= 6 * HOUR_SECONDS) {
      period = '1m';
    }

    if (duration >= WEEK_SECONDS) {
      period = '5m';
    }

    if (duration >= 4 * WEEK_SECONDS) {
      period = '30m';
    }

    if (duration >= 26 * WEEK_SECONDS) {
      period = '2h';
    }
  }

  let _measurements = '';

  measurements.forEach(
    (measurement, idx) =>
      (_measurements += `r["_measurement"] == "${measurement}" ${
        idx < measurements.length - 1 ? ' or ' : ''
      }`),
  );

  let _fields = '';
  fields.forEach(
    (field, idx) =>
      (_fields += `r["_field"] == "${field}" ${
        idx < fields.length - 1 ? ' or ' : ''
      }`),
  );
  let _channels = '';
  ovens
    .map((oven) => oven.toString())
    .forEach(
      (oven, idx) =>
        (_channels += `r["CH"] == "${oven}" ${
          idx < oven.length - 1 ? ' or ' : ''
        }`),
    );

  return `from(bucket: "${bucket}")
  |> range(start: ${start}, ${end ? `stop:${end}` : ''})
  |> filter(fn: (r) => ${_measurements})
  |> filter(fn: (r) => ${_channels})
  |> filter(fn: (r) => ${_fields})
  |> aggregateWindow(every: ${period}, fn: ${aggregation}, createEmpty: false)
  |> yield(name: "${aggregation}")
  `;
};

// query for export
export const influxExportQuery = ({
  ovens,
  measurements,
  fields,
  period = '1h',
  start,
  end,
  aggregation = 'median',
  bucket,
}: {
  ovens: number[];
  measurements: string[];
  fields: string[];
  period?: string;
  start: number;
  end?: number;
  aggregation?: string;
  bucket: string;
}): string => {
  if (!end) {
    end = Date.now();
  }

  if (!period || period === 'auto') {
    const duration = end - start;
    period = '1s';
    if (duration >= 6 * HOUR_SECONDS) {
      period = '1m';
    }

    if (duration >= WEEK_SECONDS) {
      period = '5m';
    }

    if (duration >= 4 * WEEK_SECONDS) {
      period = '30m';
    }

    if (duration >= 26 * WEEK_SECONDS) {
      period = '2h';
    }
  }

  let _measurements = '';

  measurements.forEach(
    (measurement, idx) =>
      (_measurements += `r["_measurement"] == "${measurement}" ${
        idx < measurements.length - 1 ? ' or ' : ''
      }`),
  );

  let _fields = '';
  fields.forEach(
    (field, idx) =>
      (_fields += `r["_field"] == "${field}" ${
        idx < fields.length - 1 ? ' or ' : ''
      }`),
  );
  let _channels = '';
  ovens
    .map((oven) => oven.toString())
    .forEach(
      (oven, idx) =>
        (_channels += `r["CH"] == "${oven}" ${
          idx < oven.length - 1 ? ' or ' : ''
        }`),
    );

  //|> keep(columns: ["CH", "_time", "Count_Energy",
  //  "Resistance_Continuitytest", "Resistance_Wear","Trend_Wear"])

  return `
  from(bucket: "${bucket}")
   |> range(start: ${start}, ${end ? `stop:${end}` : ''})
   |> filter(fn: (r) => ${_measurements} )
   |> filter(fn: (r) => ${_channels} )
   |> filter(fn: (r) => ${_fields} )
   |> aggregateWindow(every: ${period}, fn: ${aggregation}, createEmpty: false)
   |> pivot(rowKey:["_time"], columnKey: ["_field", "_measurement"], valueColumn: "_value")
`;
};

const sortExportData = (a: DisplayData, b: DisplayData) =>
  a.x.valueOf() - b.x.valueOf();

// Query the influx db
export const queryRows = ({
  api,
  query,
  setter,
  isExport,
  loadingSetter,
  setExporting,
}: {
  api?: QueryApi;
  query: ParameterizedQuery;
  setter: React.Dispatch<React.SetStateAction<DisplayData[]>>;
  isExport?: boolean;
  loadingSetter?: React.Dispatch<React.SetStateAction<boolean>>;
  setExporting: (exporting: boolean) => void;
}) => {
  if (!api) {
    return;
  }

  if (loadingSetter) {
    loadingSetter(true);
  }

  //make query
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const res: any[] = [];
  api.queryRows(query, {
    next(row, tableMeta) {
      const o = tableMeta.toObject(row);
      //push rows from query into an array object
      res.push(o);
    },
    complete() {
      const finalData: DisplayData[] = [];
      let fields: string[] = [];
      if (res[0]) {
        fields = Object.keys(res[0]);
      }
      for (let i = 0; i < res.length; i++) {
        const point: DisplayData = {
          x: new Date(Date.parse(res[i]['_time'])),
          y: res[i]['_value'],
        };

        if (isExport) {
          for (const field of fields) {
            point[field] = res[i][field];
          }
        }

        finalData.push(point);
      }

      finalData.sort(sortExportData);

      setter(finalData);
      if (loadingSetter) {
        loadingSetter(false);
      }
    },
    error(error) {
      // eslint-disable-next-line no-console
      console.log('query failed- ', JSON.stringify(error));
      setExporting(false);
      if (loadingSetter) {
        loadingSetter(false);
      }

      const label = isExport
        ? `Der Export is Fehlgeschlagen: ${error.message}`
        : `Daten können nicht geladen werden: ${error.message}`;

      const exportError = new EWSError({
        object: 'system',
        object_number: 0,
        message: '',
        label,
        occurred_on: new Date(Date.now()),
        error_id: Date.now().toString(),
        delete_on_hide: true,
      });
      exportError.save();
    },
  });
};
