import './_oven-status-image.scss';

import React, { useRef } from 'react';

import ITOCritical from './../../assets/ITO-critical.png';
import ITO2Good from './../../assets/ITO2-good.png';
import ITO2Neutral from './../../assets/ITO2-neutral.png';
import ITO2Warning from './../../assets/ITO2-warning.png';
export type OvenStatusImageProps = {
  height: number;
  validData: boolean;
  wear?: {
    value: number;
    warning: number;
    critical: number;
  };
  ground?: {
    value: number;
    warning: number;
    critical: number;
  };
};

export default function OvenStatusImage({
  height,
  validData,
  wear,
  ground,
}: OvenStatusImageProps) {
  const ref = useRef<HTMLDivElement>(null);

  if (typeof wear?.critical === 'string') {
    wear.critical = Number.parseInt(wear.critical);
  }
  if (typeof wear?.warning === 'string') {
    wear.warning = Number.parseInt(wear.warning);
  }

  // account for -25px margin, effectively increases image size
  height += 25;

  if (!validData || wear?.value === undefined || !ground?.value === undefined) {
    return (
      <div ref={ref} className="status-image-oven">
        <img height={height} src={ITO2Neutral} alt={'oven'} />
      </div>
    );
  }

  // • Grenzwert Verschleiß (Wear) Warning < Messwerte UND // == isOk
  // Grenzwert Kontaktierung (Ground) Warning > Messwerte: // isOk ->  grün -> ITO2Good
  // • Grenzwert Verschleiß (Wear) Warning < Messwerte UND // == isOk
  // Grenzwert Kontaktierung (Ground) Warning <= Messwerte: // )) isCritical||isWarning ->  blau -> ITO2Neutral

  // Grenzwert Verschleiß Warning < Messwerte UND Grenzwert Kontaktierung CRITICAL > Messwerte: grün
  // Grenzwert Verschleiß Warning < Messwerte UND Grenzwert Kontaktierung CRITICAL <= Messwerte: blau

  const wearWithBoundaries = getValueStatus(wear);
  const groundWithBoundaries = getValueStatus(ground);
  let image = <img height={height} src={ITO2Neutral} alt={'oven'} />;

  if (wearWithBoundaries.isOk && groundWithBoundaries.isOk) {
    /* wear ok, ground ok */
    image = <img height={height} src={ITO2Good} alt={'oven'} />;
  } else if (wearWithBoundaries.isOk && groundWithBoundaries.isWarning) {
    /* wear ok, ground critical  */
    image = <img height={height} src={ITO2Good} alt={'oven'} />;
  } else if (wearWithBoundaries.isOk && groundWithBoundaries.isCritical) {
    /* wear ok, ground critical  */
    image = <img height={height} src={ITO2Neutral} alt={'oven'} />;
  } else if (wearWithBoundaries.isWarning) {
    /* wear warning */
    image = <img height={height} src={ITO2Warning} alt={'oven'} />;
  } else {
    /* wear critical */
    image = <img height={height} src={ITOCritical} alt={'oven'} />;
  }

  return <div className="status-image-oven">{image}</div>;
}

export const getValueStatus = (obj?: {
  value: number;
  warning: number;
  critical: number;
}) => {
  if (!obj || obj.value === undefined || obj.value < 0) {
    return {
      isWarning: false,
      isCritical: true,
      isOk: false,
      status: 'undefined',
    };
  }

  const warningLower =
    !obj.warning || (obj.warning as number) === 0
      ? 0
      : (obj.warning as number) < (obj.critical as number)
        ? obj.warning
        : obj.critical;
  const warningUpper =
    !obj.warning || (obj.warning as number) === 0
      ? 0
      : (obj.warning as number) < (obj.critical as number)
        ? (obj.critical as number)
        : (obj.warning as number);

  const criticalLower =
    !obj.critical || (obj.critical as number) === 0
      ? 0
      : (obj.warning as number) < (obj.critical as number)
        ? (obj.critical as number)
        : 0;
  const criticalUpper =
    !obj.critical || (obj.critical as number) === 0
      ? 0
      : (obj.warning as number) < (obj.critical as number)
        ? 1000000
        : (obj.critical as number);

  const isWarning = (obj.warning as number)
    ? warningLower <= obj.value && obj.value <= warningUpper
    : false;

  const isCritical = (obj.critical as number)
    ? criticalLower <= obj.value && obj.value <= criticalUpper
    : false;

  const isOk = !isWarning && !isCritical;

  let status = '';
  if (isCritical) {
    status = 'critical';
  } else if (isWarning) {
    status = 'warning';
  } else {
    status = 'ok';
  }

  return {
    ...obj,
    isWarning,
    isCritical,
    isOk,
    status,
  };
};
