import { SettingsState } from './types';

export const selectOvenConfigValue = (
  state: { settings: SettingsState },
  oven: 0 | 1,
  settingName: string,
): number => {
  if (!state.settings.settingsByCategory[`oven-config-${oven}`]) {
    return 0;
  }

  const setting = state.settings.settingsByCategory[`oven-config-${oven}`].find(
    (setting) => setting.name.startsWith(`${settingName}`),
  );

  if (!setting) {
    return 0;
  }

  let value: unknown = setting.valueWithType.value;

  if (
    (setting.valueWithType.type === 'number' ||
      setting.valueWithType.type === 'number-list') &&
    typeof setting.valueWithType.value !== 'number'
  ) {
    value = Number.parseInt(value as string) as number;
  }

  return <number>value;
};

export const selectSystemConfigValue = (
  state: { settings: SettingsState },
  name: string,
): unknown => {
  if (!state.settings.settingsByCategory['system-config']) {
    return '';
  }

  const setting = state.settings.settingsByCategory['system-config'].find(
    (setting) => setting.name === name,
  );

  if (!setting) {
    return '';
  }

  return setting.valueWithType.value;
};

export const selectLiveViewValue = (
  state: { settings: SettingsState },
  name: string,
) => {
  if (!state.settings.settingsByCategory['live-view']) {
    return '';
  }

  const setting = state.settings.settingsByCategory['live-view'].find(
    (setting) => setting.name === name,
  );

  if (!setting) {
    return '';
  }

  return setting.valueWithType.value as string;
};
