import React from 'react';

import { useAppDispatch } from '../../store/hooks';
import { saveSetting } from '../../store/settings';
import { SettingType } from '../../store/settings/types';
import SettingInput from '../setting-input/setting-input';

export type SettingsGlobalProps = {
  title: string;
  settings: SettingType[];
  readonly: boolean;
  ovenIndex?: number;
};

// eslint-disable-next-line no-empty-pattern
export default function SettingsCategory({
  title,
  settings,
  readonly,
}: // ovenIndex,
SettingsGlobalProps) {
  const dispatch = useAppDispatch();

  const save = (value: SettingType) => {
    dispatch(saveSetting(value));
  };

  if (!settings) {
    return <div>Für {title} sind noch keine Einstellungen vorhanden</div>;
  }

  return (
    <div className="settings-global">
      <div className="row">
        <div className="col-12">
          <div className="fs-4 pb-2">{title}</div>
        </div>
      </div>
      <div className="card shadow p-4">
        {settings.map((setting) => (
          <div className="row mb-2" key={setting.id}>
            <div className="col-4 align-items-center">
              <div className={'row'}>
                <div className="fs-5">{setting.label || setting.name}</div>
              </div>
              {setting.caption && (
                <div className={'row'}>
                  <div className="fs-caption">{setting.caption}</div>
                </div>
              )}
            </div>
            <div className="col-8">
              <SettingInput
                setting={setting}
                readonly={readonly}
                onChange={save}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
