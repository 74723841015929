import React from 'react';

import EwsCanvas from '../../components/ews-canvas/ews-canvas';
import { nFormatter } from '../../helpers/number-helpers';

export type OvenStatusGraphProps = {
  label: string;
  value: number;
  critical?: unknown;
  warning?: unknown;
  unit?: string;
  minScaleValue?: number;
  showScale?: boolean;
};

const COLOR_OK = '#009900';
const COLOR_WARNING = '#bbbb00';
const COLOR_CRITICAL = '#990000';

export default function OvenStatusGraph({
  label,
  value,
  critical = 0,
  warning = 0,
  unit,
  showScale = true,
}: OvenStatusGraphProps) {
  if (typeof warning === 'string') {
    warning = Number.parseFloat(warning);
  }
  if (typeof critical === 'string') {
    critical = Number.parseFloat(critical);
  }

  const pixelRatio = window.devicePixelRatio;

  const currentValue = value;
  const draw = (ctx: CanvasRenderingContext2D) => {
    const width = ctx.canvas.width;
    const height = ctx.canvas.height;

    ctx.clearRect(0, 0, width, height);
    //
    // Background
    ctx.fillStyle = '#00000000';
    ctx.fillRect(0, 0, width, height);

    // Wear: max=2*Verschleiß (critical<warning) Grenzwert Warning - Continuity (critical>warning): max=2*Kontaktierung Grenzwert Critical

    const scaleMaxValue =
      (critical as number) < (warning as number)
        ? 2 * (warning as number)
        : 2 * (critical as number);

    const scaleFactor = width / scaleMaxValue;

    const verticalPadding = 10;
    const labelHeight = 36;
    const legendHeight = 10;
    const valueHeight = 32;

    const fontSizeValue = height > width ? width / 3.5 : height / 3.5;
    const fontSizeLabel = height > width ? width / 5 : height / 5;

    const warningLower: number =
      (warning as number) < (critical as number)
        ? (warning as number)
        : (critical as number);
    const warningUpper =
      (warning as number) < (critical as number)
        ? (critical as number)
        : (warning as number);

    const criticalLower =
      (warning as number) < (critical as number) ? (critical as number) : -1000;
    const criticalUpper: number =
      (warning as number) < (critical as number)
        ? Number.MAX_SAFE_INTEGER - 1
        : (critical as number);

    const isWarning = (warning as number)
      ? warningLower <= currentValue && currentValue <= warningUpper
      : false;

    const isCritical = (critical as number)
      ? criticalLower <= currentValue && currentValue <= criticalUpper
      : false;

    if (showScale) {
      const verticalPaddingFactor = 3;
      ctx.fillStyle = COLOR_OK;
      ctx.fillRect(
        0,
        labelHeight + verticalPadding * verticalPaddingFactor,
        width,
        legendHeight,
      );

      if (warning as number) {
        ctx.fillStyle = COLOR_WARNING;
        ctx.fillRect(
          warningLower * scaleFactor,
          labelHeight + verticalPadding * verticalPaddingFactor,
          warningUpper * scaleFactor - warningLower * scaleFactor,
          legendHeight,
        );
      }

      if (critical as number) {
        ctx.fillStyle = COLOR_CRITICAL;
        if ((warning as number) < (critical as number)) {
          ctx.fillRect(
            criticalLower * scaleFactor,
            labelHeight + verticalPadding * verticalPaddingFactor,
            width,
            legendHeight,
          );
        } else {
          ctx.fillRect(
            criticalLower * scaleFactor,
            labelHeight + verticalPadding * verticalPaddingFactor,
            criticalUpper * scaleFactor - criticalLower * scaleFactor,
            legendHeight,
          );
        }
      }

      // Value Bar
      if (currentValue !== undefined) {
        ctx.fillStyle = '#999999';
        ctx.fillRect(
          0,
          legendHeight + labelHeight + verticalPadding * 4,
          width,
          valueHeight,
        );
        ctx.fillStyle = isCritical
          ? COLOR_CRITICAL
          : isWarning
            ? COLOR_WARNING
            : COLOR_OK;
        ctx.fillRect(
          0,
          legendHeight + labelHeight + verticalPadding * 4,
          currentValue * scaleFactor,
          valueHeight,
        );
      }
    }

    ctx.fillStyle = isCritical
      ? COLOR_CRITICAL
      : isWarning
        ? COLOR_WARNING
        : COLOR_OK;
    ctx.font = `${fontSizeValue}px sans-serif`;
    ctx.textAlign = 'center';

    const y = showScale
      ? legendHeight +
        labelHeight +
        valueHeight +
        verticalPadding * 6 +
        fontSizeValue
      : legendHeight + labelHeight + valueHeight + fontSizeValue;

    const valueYPos = pixelRatio === 1 ? y - 10 : y;
    ctx.fillText(nFormatter(currentValue, 1, unit), width / 2, valueYPos);
    ctx.fillStyle = '#999999';

    ctx.font = `${fontSizeLabel}px sans-serif`;
    ctx.textAlign = 'center';
    ctx.fillText(`${label}`, width / 2, labelHeight + verticalPadding * 2);
  };

  return (
    <div className="oven-status-graph">
      <EwsCanvas draw={draw} />
    </div>
  );
}
