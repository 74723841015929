import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Role } from '../../providers/auth-provider/auth-provider';
import { useParse } from '../../providers/parse-provider/parse-provider';
import { User } from '../../stories/user-list/user-list';
import { UserState } from './types';

const Parse = useParse();

export const initialState: UserState = {
  users: [],
  status: 'idle',
};

const getUserRoles = async (user: Parse.User): Promise<Parse.Role[]> => {
  const query = new Parse.Query(Parse.Role);
  query.equalTo('users', user);
  return await query.find();
};

const handleUserRoles = async (
  user: User,
  parseUser: Parse.User,
): Promise<void> => {
  const parseRoles = await new Parse.Query(Parse.Role).find();
  if (!user.roles) {
    return;
  }
  for (const parseRole of parseRoles) {
    if (user.roles.includes(parseRole.getName())) {
      parseRole.getUsers().add(parseUser);
    }

    if (!user.roles.includes(parseRole.getName())) {
      parseRole.getUsers().remove(parseUser);
    }

    await parseRole.save();
  }
};

export const loadAllUsers = createAsyncThunk('user/loadAllUsers', async () => {
  const users = await new Parse.Query(Parse.User).findAll();
  const nextUsers: User[] = [];

  for (const puser of users) {
    nextUsers.push(userFromParse(puser, await getUserRoles(puser)));
  }

  return nextUsers;
});

export const createUser = createAsyncThunk(
  'users/create',
  async (user: User, { rejectWithValue }) => {
    if (user.username === 'admin') {
      return rejectWithValue('Cannot modify admin user');
    }
    const pUser: Parse.User = new Parse.User({
      username: user.username,
      password: user.password,
    });

    const nextPUser = await pUser.save(
      {},
      {
        sessionToken: Parse.User.current()?.getSessionToken(),
      },
    );

    await handleUserRoles(user, nextPUser);

    return userFromParse(nextPUser, await getUserRoles(nextPUser));
  },
);

export const saveUser = createAsyncThunk('users/save', async (user: User) => {
  const pUser: Parse.User = await new Parse.Query(Parse.User).get(user.id);

  if (user.password) {
    pUser.setPassword(user.password);
  }

  if (user.username) {
    pUser.setUsername(user.username);
  }

  const nextPUser = await pUser.save(
    {},
    {
      sessionToken: Parse.User.current()?.getSessionToken(),
    },
  );

  await handleUserRoles(user, nextPUser);

  return userFromParse(nextPUser, await getUserRoles(nextPUser));
});

export const deleteUser = createAsyncThunk(
  'user/delete',
  async (user: User, { rejectWithValue }) => {
    if (user.username === 'admin') {
      return rejectWithValue('Cannot modify admin user');
    }
    const pUser: Parse.User = await new Parse.Query(Parse.User).get(user.id);
    pUser.destroy({});

    return Object.assign({}, user, { state: 'deleted' });
  },
);

export const userlistSlice = createSlice({
  name: 'userlist',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    // saveUser: (state, action: PayloadAction<User>) => {},
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      /// Load All
      .addCase(loadAllUsers.pending, (state) => {
        state.error = null;
        state.status = 'loading';
      })
      .addCase(loadAllUsers.fulfilled, (state, action) => {
        state.status = 'idle';
        state.users = action.payload;
      })
      .addCase(loadAllUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      /// Create User
      .addCase(createUser.pending, (state) => {
        state.error = null;
        state.status = 'saving';
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.status = 'idle';
        state.users.unshift(action.payload);
      })
      .addCase(createUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      /// Save User
      .addCase(saveUser.pending, (state) => {
        state.error = null;
        state.status = 'saving';
      })
      .addCase(saveUser.fulfilled, (state, action) => {
        state.status = 'idle';
        const nextUserIdx = state.users.findIndex(
          (user) => user.id === action.payload.id,
        );
        state.users[nextUserIdx] = action.payload;
      })
      .addCase(saveUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      /// Delete User
      .addCase(deleteUser.pending, (state) => {
        state.error = null;
        state.status = 'saving';
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.status = 'idle';
        const nextUserIdx = state.users.findIndex(
          (user) => user.id === action.payload.id,
        );
        state.users.splice(nextUserIdx, 1);
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message + ' ' + action.payload;
      });
  },
});

export default userlistSlice.reducer;

const userFromParse = (pUser: Parse.User, roles: Parse.Role[]): User => {
  return {
    id: pUser.id,
    username: pUser.attributes.username,
    roles: roles.map((role) => Role[role.getName() as keyof typeof Role]),
    updatedAt: pUser.updatedAt.toISOString(),
  };
};
