import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { useParse } from '../../providers/parse-provider/parse-provider';
import {
  Setting,
  SettingsMap,
  SettingSource,
  SettingsState,
  SettingType,
  SettingValueType,
  SystemSettings,
} from './types';

const Parse = useParse();

Parse.Object.registerSubclass('Setting', Setting);

export const initialState: SettingsState = {
  settingsByCategory: {},
  settings: [],
  status: 'idle',
  error: undefined,
};

const initializeLiveView = async () => {
  for (const setting of liveViewSettings) {
    await setting.save();
  }
};

const initializeOvenGui = async (ovens: number) => {
  for (let i = 0; i < ovens; i++) {
    for (const setting of ovenGuiSettings(i)) {
      await setting.save();
    }
  }
};

const initializeSystemGui = async () => {
  for (const setting of systemGuiSettings) {
    await setting.save();
  }
};

export const loadSettings = createAsyncThunk('settings/load', async () => {
  const systemConfigSettings: Setting[] =
    await Parse.Cloud.run('getSystemConfig');

  const ovenSetting = systemConfigSettings.find(
    (setting) => setting.attributes['name'] === SystemSettings.ovens,
  );

  const ovenQueryArray: number[] = [];
  if (ovenSetting) {
    const countOvens: number = parseInt(
      ovenSetting.attributes.valueWithType.value,
    );
    for (let i = 0; i < countOvens; i++) {
      ovenQueryArray.push(i);
    }
  } else {
    ovenQueryArray.push(0);
  }

  const ovenConfigSettings = await Parse.Cloud.run('getOvenConfig', {
    ovens: ovenQueryArray,
  });
  let liveViewSettings = await new Parse.Query(Setting)
    .equalTo('category', 'live-view')
    .find();

  if (liveViewSettings.length === 0) {
    await initializeLiveView();
    liveViewSettings = await new Parse.Query(Setting)
      .equalTo('category', 'live-view')
      .find();
  }

  const ovenGuiSettings = await new Parse.Query(Setting)
    .startsWith('category', 'gui-oven-')
    .find();
  if (ovenGuiSettings.length === 0) {
    await initializeOvenGui(2);
  }

  const systemGuiSetting = await new Parse.Query(Setting)
    .equalTo('category', 'gui-system')
    .find();
  if (systemGuiSetting.length === 0) {
    await initializeSystemGui();
  }

  const settings = [
    ...systemConfigSettings,
    ...ovenConfigSettings,
    ...liveViewSettings,
    ...ovenGuiSettings,
    ...systemGuiSetting,
  ];

  const settingBeans: SettingType[] = [];
  for (const s of settings) {
    settingBeans.push(s.toBean());
  }
  return settingBeans;
});

export const loadSingleSetting = createAsyncThunk(
  'settings/loadSingle',
  async (options: { name: string; category: string }, { rejectWithValue }) => {
    const settingQuery = await new Parse.Query(Setting);
    settingQuery.equalTo('name', options.name);
    settingQuery.equalTo('category', options.category);
    const results = await settingQuery.find();

    if (results.length !== 1) {
      rejectWithValue('Setting name/category must exist and be unique');
    }

    return results[0];
  },
);

export const saveSetting = createAsyncThunk(
  'settings/save',
  async (settingBean: SettingType, { rejectWithValue }) => {
    const pSetting = await new Parse.Query(Setting).get(settingBean.id!);

    if (settingBean.valueWithType) {
      pSetting.set('valueWithType', settingBean.valueWithType);
    }

    try {
      const result = await pSetting.save();
      return result.toBean();
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      /// Load all Settings
      .addCase(loadSettings.pending, (state) => {
        state.error = null;
        state.status = 'loading';
      })
      .addCase(loadSettings.fulfilled, (state, action) => {
        state.settingsByCategory = updateAllSettingsByCategory(action.payload);
        state.settings = action.payload.sort(sortByOrder);
        state.error = null;
        state.status = 'idle';
      })
      .addCase(loadSettings.rejected, (state, action) => {
        state.error = `Einstellungen können nicht geladen werden: ${action.error.message}`;
        state.status = 'idle';
      })
      /// Load single setting
      .addCase(loadSingleSetting.pending, (state) => {
        state.error = null;
        state.status = 'loading';
      })
      .addCase(loadSingleSetting.fulfilled, (state, action) => {
        const idx = state.settings.findIndex(
          (setting) => setting.id === action.payload.id,
        );

        if (idx === -1) {
          state.settings.push(action.payload);
        } else {
          state.settings[idx] = action.payload;
        }
        const catIdx = state.settingsByCategory[
          action.payload.category
        ].findIndex((setting) => setting.id === action.payload.id);
        if (catIdx === -1) {
          state.settingsByCategory[action.payload.category].push(
            action.payload,
          );
        } else {
          state.settingsByCategory[action.payload.category][catIdx] =
            action.payload;
        }
        state.error = null;
        state.status = 'idle';
      })
      .addCase(loadSingleSetting.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = 'idle';
      })
      /// Save Setting
      .addCase(saveSetting.pending, () => {
        // silent save
      })
      .addCase(saveSetting.fulfilled, (state, action) => {
        state.error = null;
        state.status = 'idle';
        if (action.payload) {
          const idx = state.settings.findIndex(
            (setting) => setting.id === action.payload?.id,
          );
          state.settings[idx] = action.payload;
        }
      })
      .addCase(saveSetting.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = 'idle';
      });
  },
});

export default settingsSlice.reducer;

/// Data Helper Functions
function updateAllSettingsByCategory(settings: SettingType[]) {
  const nextSettingsByCategory: SettingsMap = {};
  for (let i = 0; i < settings.length; i++) {
    const category = settings[i].category;
    if (!nextSettingsByCategory[category]) {
      nextSettingsByCategory[category] = [];
    }
    nextSettingsByCategory[category].push(settings[i]);
  }

  for (const category of Object.keys(nextSettingsByCategory)) {
    nextSettingsByCategory[category].sort(sortByOrder);
  }
  return nextSettingsByCategory;
}

/*
 * Sort Settings by its sortOrder Property
 */
export const sortByOrder = (a: SettingType, b: SettingType) =>
  (a.sortOrder || 0) - (b.sortOrder || 0);

/// Data for initializing settings and ovens
const liveViewSettings: Setting[] = [
  new Setting({
    name: 'anzeigeintervall',
    label: 'Anzeigeintervall',
    category: 'live-view',
    valueWithType: {
      value: 30,
      type: SettingValueType.numberList,
      unit: 'Minuten',
    },
    options: [5, 10, 15, 20, 30, 60, 120, 180],
    sortOrder: 0,
    source: SettingSource.parse,
  }),
];

const ovenGuiSettings = (num: number): Setting[] => [
  new Setting({
    name: `show-ekg-${num}`,
    label: 'Ansicht EKG',
    caption: 'GUI',
    category: `gui-oven-${num}`,
    valueWithType: {
      value: true,
      type: SettingValueType.boolean,
    },
    sortOrder: 0,
    source: SettingSource.parse,
  }),
  new Setting({
    name: `show-contact-melt-${num}`,
    label: 'Ansicht Kontaktierung Schmelze',
    caption: 'GUI',
    category: `gui-oven-${num}`,
    valueWithType: {
      value: true,
      type: SettingValueType.boolean,
    },
    sortOrder: 1,
    source: SettingSource.parse,
  }),
  new Setting({
    name: `show-power-${num}`,
    label: 'Ansicht Leistung',
    caption: 'GUI',
    category: `gui-oven-${num}`,
    valueWithType: {
      value: true,
      type: SettingValueType.boolean,
    },
    sortOrder: 2,
    source: SettingSource.parse,
  }),
  new Setting({
    name: `show-wear-${num}`,
    label: 'Ansicht Verschleiß',
    caption: 'GUI',
    category: `gui-oven-${num}`,
    valueWithType: {
      value: true,
      type: SettingValueType.boolean,
    },
    sortOrder: 3,
    source: SettingSource.parse,
  }),
  new Setting({
    name: `description-${num}`,
    label: 'Beschreibung',
    caption: 'Backend',
    category: `oven-config-${num}`,
    valueWithType: {
      value: 'Ofenbeschreibung',
      type: SettingValueType.string,
    },
    sortOrder: 4,
    source: SettingSource.parse,
  }),
];

// Allgemeine Einstellungen
const systemGuiSettings: Setting[] = [
  new Setting({
    name: SystemSettings.showMaintenanceWarning,
    label: 'Warnung Wartung anzeigen',
    category: 'gui-system',
    valueWithType: {
      value: true,
      type: SettingValueType.boolean,
    },
    sortOrder: 5,
    source: SettingSource.parse,
  }),
  new Setting({
    name: SystemSettings.maintenanceWarningText,
    label: 'Text Warnung Wartung',
    category: 'gui-system',
    valueWithType: {
      value: 'Warnung fällig',
      type: SettingValueType.string,
    },
    sortOrder: 6,
    source: SettingSource.parse,
  }),
  new Setting({
    name: SystemSettings.licenceValidUntil,
    label: 'Lizenz-Ablauf am',
    category: 'gui-system',
    valueWithType: {
      value: null,
      type: SettingValueType.date,
    },
    sortOrder: 7,
    source: SettingSource.parse,
  }),
  new Setting({
    name: SystemSettings.licenceWarningText,
    label: 'Warnung Lizenz anzeigen',
    category: 'gui-system',
    valueWithType: {
      value: 'Lizenz läuft bald ab',
      type: SettingValueType.string,
    },
    sortOrder: 8,
    source: SettingSource.parse,
  }),
  new Setting({
    name: SystemSettings.daysBeforeLicenceEndsWarning,
    label: 'Warnen Lizenzablauf Tage vor Ende',
    category: 'gui-system',
    valueWithType: {
      value: 30,
      type: SettingValueType.number,
      unit: 'Tage',
    },
    sortOrder: 9,
    source: SettingSource.parse,
  }),
  new Setting({
    name: 'influx-token',
    label: 'Influx API Token',
    category: 'system-config',
    valueWithType: {
      value:
        '_yuPjKfxRyJphJp_yrFqWTyjqNNg5ZkTM4tJLTONJVvV12nWeLkEJOo2lJVbSo7LKv_-7Vgr3F1sv_ELS65vAw==',
      type: SettingValueType.string,
    },
    sortOrder: 100,
    source: SettingSource.parse,
  }),
  new Setting({
    name: 'influx-org',
    label: 'Influx ORG',
    category: 'system-config',
    valueWithType: {
      value: 'ews',
      type: SettingValueType.string,
    },
    sortOrder: 101,
    source: SettingSource.parse,
  }),
  new Setting({
    name: 'influx-url',
    label: 'Influx URL',
    category: 'system-config',
    valueWithType: {
      value: 'https://localhost/influx',
      type: SettingValueType.string,
    },
    sortOrder: 102,
    source: SettingSource.parse,
  }),
];
