import './_oven-live.scss';

import {
  InfluxDB,
  ParameterizedQuery,
  QueryApi,
} from '@influxdata/influxdb-client';
import React, { useEffect, useRef, useState } from 'react';

import EkgLine from '../../components/ekg-line/ekg-line';
import { nFormatter } from '../../helpers/number-helpers';
import { useResize } from '../../helpers/use-resize';
import { useAppSelector } from '../../store/hooks';
import {
  selectLiveViewValue,
  selectSystemConfigValue,
} from '../../store/settings/selectors';
import { SettingsState, SettingType } from '../../store/settings/types';
import { renderAlert } from '../error-messages/error-item';
import OvenStatusGraph from '../oven-status-graph/oven-status-graph';
import OvenStatusImage from '../oven-status-image/oven-status-image';
import RealtimeLineChart from '../realtime-line-chart/realtime-line-chart';

const MINUTE_MILLS = 60000;

type InfluxDataPoint = {
  CH: string;
  result: string;
  table: number;
  _field: string;
  _measurement: string;
  _start: string;
  _stop: string;
  _time: string;
  _value: number;
};

export type DataPoint = {
  x: Date;
  y: number;
};

export const validData = (date: Date | undefined): boolean => {
  if (!date) {
    return false;
  }
  return Date.now() - date.valueOf() < MINUTE_MILLS / 2;
};

const influxQuery = ({
  name,
  start,
  bucket,
}: {
  name: number;
  start: string;
  bucket: string;
}): string => `from(bucket: "${bucket}")
  |> range(start: ${start})
  |> filter(fn: (r) => r["_measurement"] == "Continuitytest" or r["_measurement"] == "Energy" or r["_measurement"] == "Wear")
  |> filter(fn: (r) => r["CH"] == "${name + 1}")
  |> filter(fn: (r) => r["_field"] == "Count" or r["_field"] == "Resistance")
  |> aggregateWindow(every: 1s, fn: mean, createEmpty: false)
  |> yield(name: "mean")
  `;

const influxEkgQuery = ({
  name,
  bucket,
}: {
  name: number;
  bucket: string;
}): string => `from(bucket: "${bucket}")
        |> range(start: -10s)
        |> filter(fn: (r) => r["CH"] == "${name + 1}")
        |> filter(fn: (r) => r["_measurement"] == "Continuitytest" and r["_field"] == "Resistance")
        |> aggregateWindow(every: 1s, fn: last, createEmpty: false)
        |> yield(name: "last")`;

export type OvenLiveProps = {
  name: string;
  gui: SettingType[];
  config: SettingType[];
  liveView: SettingType[];
};

export default function OvenLive({
  name,
  gui,
  config,
  liveView,
}: OvenLiveProps) {
  const [settings, setSettings] = useState<{
    [key: string]: number | boolean | string | unknown;
  }>({});

  let requestingData = false;
  let queryTimer: NodeJS.Timeout | undefined;
  const [query, setQuery] = useState<string>();
  const [wearData, setWearData] = useState<DataPoint[]>([]);
  const [groundData, setGroundData] = useState<DataPoint[]>([]);
  const [ekgData, setEkgData] = useState<DataPoint>();
  const [powerData, setPowerData] = useState<DataPoint[]>([]);
  const [influxQueryApi, setInfluxQueryApi] = useState<QueryApi>();
  const influxError = useRef<Error | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>();

  const influxToken: string = useAppSelector(
    (state: { settings: SettingsState }) =>
      selectSystemConfigValue(state, 'influx-token') as string,
  );

  const influxOrg: string = useAppSelector(
    (state: { settings: SettingsState }) =>
      selectSystemConfigValue(state, 'influx-org') as string,
  );

  const influxBucket: string = useAppSelector(
    (state: { settings: SettingsState }) =>
      selectSystemConfigValue(state, 'influx-bucket') as string,
  );

  const influxUrl: string = useAppSelector(
    (state: { settings: SettingsState }) =>
      selectSystemConfigValue(state, 'influx-url') as string,
  );

  const liveViewInterval = useAppSelector(
    (state: { settings: SettingsState }) =>
      selectLiveViewValue(state, 'anzeigeintervall'),
  );

  useEffect(() => {
    setInfluxQueryApi(
      new InfluxDB({
        url: influxUrl,
        token: influxToken,
      }).getQueryApi(influxOrg),
    );
  }, [influxOrg, influxToken, influxUrl, influxBucket]);

  useEffect(() => {
    const query = influxQuery({
      name: Number.parseInt(name),
      start: `-${liveViewInterval}m`,
      bucket: influxBucket,
    });
    setQuery(query);
  }, [influxQueryApi, liveViewInterval]);

  useEffect(() => {
    queryTimer = setInterval(queryData, 2000);

    return () => {
      clearInterval(queryTimer);
    };
  }, [query]);

  useEffect(() => {
    if (influxError.current !== null) {
      clearInterval(queryTimer);
    } else if (!queryTimer) {
      queryTimer = setInterval(queryData, 2000);
    }
  }, [errorMessage]);

  useEffect(() => {
    const nextSettings: {
      [key: string]: number | boolean | string | unknown;
    } = {};

    if (gui) {
      for (const setting of gui) {
        nextSettings[setting.name] = setting.valueWithType.value;
      }
    }

    if (config) {
      for (const setting of config) {
        nextSettings[setting.name] = setting.valueWithType.value;
      }
    }

    if (liveView) {
      for (const setting of liveView) {
        nextSettings[setting.name] = setting.valueWithType.value;
      }
    }

    setSettings(nextSettings);
  }, [gui, config]);

  const queryData = () => {
    if (influxError.current === null && query && !requestingData) {
      requestingData = true;
      queryRows(query).finally(() => (requestingData = false));
      queryEkgData(
        influxEkgQuery({ name: Number.parseInt(name), bucket: influxBucket }),
      );
    }
  };

  const queryEkgData = async (query: string) => {
    if (!influxQueryApi || influxError.current) {
      return;
    }
    const res: InfluxDataPoint[] = [];
    await influxQueryApi.queryRows(query, {
      next(row, tableMeta) {
        const o = tableMeta.toObject(row) as unknown as InfluxDataPoint;
        //push rows from query into an array object
        res.push(o);
      },
      complete() {
        if (res.length > 0) {
          const point: { x: Date; y: number } = {
            x: new Date(Date.parse(res[res.length - 1]['_time'])),
            y: res[res.length - 1]['_value'],
          };
          setEkgData(point);
        }
      },
      error(error) {
        // eslint-disable-next-line no-console
        console.log('query failed - ', error);
        influxError.current = error;
      },
    });
  };

  const queryRows = async (query: ParameterizedQuery) => {
    if (!influxQueryApi || influxError.current) {
      return;
    }

    //make query
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res: InfluxDataPoint[] = [];
    await influxQueryApi.queryRows(query, {
      next(row, tableMeta) {
        const o = tableMeta.toObject(row) as unknown as InfluxDataPoint;
        //push rows from query into an array object
        res.push(o);
      },
      complete() {
        setWearData(
          extractPoints({ res, measurement: 'Wear', field: 'Resistance' }),
        );
        setGroundData(
          extractPoints({
            res,
            measurement: 'Continuitytest',
            field: 'Resistance',
          }),
        );
        setPowerData(
          extractPoints({
            res,
            measurement: 'Energy',
            field: 'Count',
          }),
        );
      },
      error(error) {
        // eslint-disable-next-line no-console
        console.log('query failed- ', error);
        influxError.current = error;
        setErrorMessage(error.message);
      },
    });
  };

  const extractPoints = ({
    res,
    measurement,
    field,
  }: {
    res: InfluxDataPoint[];
    measurement: string;
    field: string;
  }): DataPoint[] => {
    const finalData: { x: Date; y: number }[] = [];
    const filtered = res.filter(
      (point: InfluxDataPoint) =>
        point._measurement == measurement && point._field === field,
    );
    for (let i = 0; i < filtered.length; i++) {
      const point: { x: Date; y: number } = {
        x: new Date(Date.parse(filtered[i]['_time'])),
        y: filtered[i]['_value'],
      };
      finalData.push(point);
    }

    return finalData;
  };

  const showWear = gui && settings[`show-wear-${name}`] === true;
  const showEkg = gui && settings[`show-ekg-${name}`] === true;
  const showPower = gui && settings[`show-power-${name}`] === true;
  const showContinuity = gui && settings[`show-contact-melt-${name}`] === true;

  const numberCharts = +showWear + +showPower + +showContinuity;

  const size = useResize();
  const heightChunk = size.height / 12;

  const descriptionHeight = heightChunk / 2; // use always once
  const ekgHeight = 4 * heightChunk; // used always (at least for oven image)
  const chartHeight = (6 / numberCharts) * heightChunk; // use dependend on settings

  const legend = () => (
    <div>
      <span className={'fs-5'}>Signalisierungsgrenzen Wear: </span>
      <span className={'fs-5 color-green fw-bold text-nowrap'}>
        {`R > ${nFormatter(
          settings[`threshold_melt_advance-${name}`] as number,
          0,
          'Ω',
        )}: Normal `}
      </span>
      |
      <span className={'fs-5 color-yellow fw-bold text-nowrap'}>
        {`  ${nFormatter(
          settings[`threshold_melt_advance-${name}`] as number,
          0,
          'Ω',
        )} > R > ${nFormatter(
          settings[`threshold_melt_critical-${name}`] as number,
          0,
          'Ω',
        )}: Warnung  `}
      </span>
      |
      <span className={'fs-5 color-alert fw-bold text-nowrap'}>
        {`  R < ${nFormatter(
          settings[`threshold_melt_critical-${name}`] as number,
          0,
          'Ω',
        )}: kritisch  `}
      </span>
    </div>
  );

  return (
    <div className="oven-live" style={{ height: `${heightChunk * 10.5}px` }}>
      {influxError.current &&
        renderAlert({
          error: {
            title: 'Fehler',
            message: `Von ${influxUrl} können keine Daten abgerufen werden.`,
            key: 'influx_critical',
            dismissable: false,
          },
        })}

      <div style={{ height: `${descriptionHeight}px` }}>
        <span className={'fs-4'}>
          Ofen {Number.parseInt(name) + 1}
          {!!settings[`description-${name}`] && <span>&nbsp; — &nbsp;</span>}
        </span>
        {!!settings[`description-${name}`] && (
          <span className={'fs-5'}>
            {settings[`description-${name}`] as string}
          </span>
        )}
      </div>

      <div
        className={'card shadow'}
        style={{
          height: `${10.25 * heightChunk}px`,
          padding: `${0.25 * heightChunk}px ${0.25 * heightChunk}px ${
            0.25 * heightChunk
          }px 0`,
        }}
      >
        {/*EKG*/}
        <div className={'row'} style={{ height: `${ekgHeight}px` }}>
          <div className={'col-1 align-self-start'}>
            {showEkg && <div className={'rotated color-lightgray'}>EKG</div>}
          </div>
          <div
            className={showEkg && !showWear ? 'col-11' : 'col-6'}
            style={{ height: `${ekgHeight * 0.75}px` }}
          >
            {showEkg && ekgData && validData(ekgData?.x) && (
              <EkgLine
                height={ekgHeight * 0.75}
                current={ekgData ? ekgData.y : 0}
                groundWarning={
                  settings[`threshold_ground_advance-${name}`] as number
                }
                groundCritical={
                  settings[`threshold_ground_critical-${name}`] as number
                }
              />
            )}
            {showEkg && (!ekgData || !validData(ekgData?.x)) && (
              <div
                style={{ height: 156 }}
                className={'text-center color-alert'}
              >
                Keine Daten
              </div>
            )}
            {showWear && legend()}
          </div>
          <div
            className={
              showEkg && !showWear
                ? 'col-auto oven-image-column'
                : 'col-5 oven-image-column'
            }
          >
            {showWear && (
              <OvenStatusImage
                height={ekgHeight}
                validData={
                  wearData.length > 0 &&
                  validData(wearData[wearData.length - 1]?.x)
                }
                wear={{
                  value:
                    wearData.length > 0 ? wearData[wearData.length - 1].y : 0,
                  warning: settings[`threshold_melt_advance-${name}`] as number,
                  critical: settings[
                    `threshold_melt_critical-${name}`
                  ] as number,
                }}
                ground={{
                  value:
                    groundData.length > 0
                      ? groundData[groundData.length - 1].y
                      : 0,
                  warning: settings[
                    `threshold_ground_advance-${name}`
                  ] as number,
                  critical: settings[
                    `threshold_ground_critical-${name}`
                  ] as number,
                }}
              />
            )}
          </div>
        </div>

        {/*Wear*/}
        {showWear && (
          <div className={'row'} style={{ height: `${chartHeight}px` }}>
            <div className={'col-1 align-self-center'}>
              <div className={'rotated color-lightgray'}>Wear</div>
            </div>
            <div className={'col-6  align-self-center'}>
              <div className={'W200C100'}>
                <RealtimeLineChart
                  data={wearData}
                  curveColor={'#0099ff'}
                  warning={settings[`threshold_melt_advance-${name}`] as number}
                  critical={
                    settings[`threshold_melt_critical-${name}`] as number
                  }
                  unitY={'Ω'}
                  interval={Number.parseInt(liveViewInterval)}
                />
              </div>
            </div>
            <div className={'col-5 align-self-center'}>
              {!validData(wearData[wearData.length - 1]?.x) && (
                <div className={'color-alert'}>Keine aktuellen Daten</div>
              )}
              {validData(wearData[wearData.length - 1]?.x) && (
                <OvenStatusGraph
                  label={'Wear'}
                  value={
                    wearData.length > 0 ? wearData[wearData.length - 1].y : 0
                  }
                  warning={settings[`threshold_melt_advance-${name}`] as number}
                  critical={
                    settings[`threshold_melt_critical-${name}`] as number
                  }
                  unit={'Ω'}
                />
              )}
            </div>
          </div>
        )}
        {/*Ground*/}
        {showContinuity && (
          <div className={'row'} style={{ height: `${chartHeight}px` }}>
            <div className={'col-1 align-self-center'}>
              <div className={'rotated color-lightgray'}>Continuity</div>
            </div>
            <div className={'col-6 align-self-center'}>
              <div className={''}>
                <RealtimeLineChart
                  data={groundData}
                  curveColor={'#000000'}
                  warning={
                    settings[`threshold_ground_advance-${name}`] as number
                  }
                  critical={
                    settings[`threshold_ground_critical-${name}`] as number
                  }
                  unitY={'Ω'}
                  interval={Number.parseInt(liveViewInterval)}
                />
              </div>
            </div>
            <div className={'col-5  align-self-center'}>
              {!validData(groundData[groundData.length - 1]?.x) && (
                <div className={'color-alert'}>Keine aktuellen Daten</div>
              )}
              {validData(groundData[groundData.length - 1]?.x) && (
                <OvenStatusGraph
                  label={'Continuity'}
                  warning={
                    settings[`threshold_ground_advance-${name}`] as number
                  }
                  critical={
                    settings[`threshold_ground_critical-${name}`] as number
                  }
                  value={
                    groundData.length > 0
                      ? groundData[groundData.length - 1].y
                      : 0
                  }
                  unit={'Ω'}
                />
              )}
            </div>
          </div>
        )}
        {/*Power*/}
        {showPower && (
          <div className={'row'} style={{ height: `${chartHeight}px` }}>
            <div className={'col-1 align-self-center'}>
              <div className={'rotated color-lightgray'}>Power</div>
            </div>
            <div className={'col-6  align-self-center'}>
              <div className={''}>
                <RealtimeLineChart
                  data={powerData}
                  curveColor={'#ff3333'}
                  unitY={'W'}
                  interval={Number.parseInt(liveViewInterval)}
                />
              </div>
            </div>
            <div className={'col-5 align-self-center'}>
              {!validData(powerData[powerData.length - 1]?.x) && (
                <div className={'color-alert'}>Keine aktuellen Daten</div>
              )}
              {validData(powerData[powerData.length - 1]?.x) && (
                <OvenStatusGraph
                  label={'Power'}
                  showScale={false}
                  value={
                    powerData.length > 0 ? powerData[powerData.length - 1].y : 0
                  }
                  unit={'W'}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
