import { useParse } from '../../providers/parse-provider/parse-provider';
const Parse = useParse();

export interface EWSErrorType {
  object: string;
  object_number: number;
  message: string;
  label: string;
  occurred_on: Date;
  acknowledged_on?: Date | null;
  acknowledged_by?: string | null;
  solved_on?: Date | null;
  solved_by?: string | null;
  error_id?: string | null;
  delete_on_hide?: boolean;
}

export class EWSError extends Parse.Object {
  constructor({
    object,
    object_number,
    message,
    label,
    occurred_on,
    acknowledged_on,
    acknowledged_by,
    solved_on,
    solved_by,
    error_id,
    delete_on_hide = false,
  }: EWSErrorType) {
    super('Error');
    this.set('object', object);
    this.set('object_number', object_number);
    this.set('message', message);
    this.set('label', label);
    this.set('occurred_on', occurred_on);
    this.set('acknowledged_on', acknowledged_on);
    this.set('acknowledged_by', acknowledged_by);
    this.set('solved_on', solved_on);
    this.set('solved_by', solved_by);
    this.set('error_id', error_id);
    this.set('delete_on_hide', delete_on_hide);
  }
}
