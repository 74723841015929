import './_error-messages.scss';

import React from 'react';
import { Alert, Button } from 'react-bootstrap';

import { ReactComponent as IconWarning } from '../../assets/road-sign-warning.svg';
import { formatTimeAndDate } from '../../helpers/date-helpers';

export const errorHeadlines: { [key: string]: string } = {
  license_end_warning: 'Ablauf der Lizenz',
  ground_critical: 'Kontaktierung kritisch',
  ground_advance: 'Kontaktierung Warnung',
  system_error: 'Systemfehler',
};

export type UIErrorType = {
  id?: string;
  title: string;
  message: string;
  key: string;
  occurred_on?: string;
  dismissable?: boolean;
};

export const renderParseAlert = (
  error: Parse.Object,
  onAcknowledge?: (error: Parse.Object) => void,
  onClose?: (error: Parse.Object) => void,
  index?: number,
) =>
  renderAlert({
    error: {
      id: error.id,
      title: errorHeadlines[error.attributes.message as string],
      message: error.attributes.label,
      key: error.attributes.message as string,
      occurred_on: formatTimeAndDate(
        new Date(Date.parse(error.attributes.occurred_on)),
      ),
    },
    onAcknowledge: () => {
      if (typeof onAcknowledge === 'function') {
        onAcknowledge(error);
      }
    },
    onClose: () => {
      if (typeof onClose === 'function') {
        onClose(error);
      }
    },
    index,
  });

export const renderAlert = ({
  error,
  onAcknowledge,
  onClose,
  index,
}: {
  error: UIErrorType;
  onAcknowledge?: (error: UIErrorType) => void;
  onClose?: (error: UIErrorType) => void;
  index?: number;
}) => (
  <Alert
    key={error.id || Math.random().toString()}
    className={`alert fixed ${
      index && index < 5
        ? 'pretty-margin-top-' + index
        : index && index >= 5
          ? 'pretty-margin-top-4'
          : ''
    }`}
    // variant={'light'}
    variant={error.key && error.key.includes('critical') ? 'danger' : 'light'}
    dismissible={error.dismissable !== undefined ? error.dismissable : true}
    closeLabel={'Bestätigen'}
    onClose={() => {
      if (typeof onClose === 'function') {
        onClose(error);
      }
    }}
  >
    <div className={'d-flex row'}>
      <div className={'col-2'}>
        <IconWarning stroke="currentColor" />
      </div>
      <div className={'col-10'}>
        <div className={'row'}>
          <div
            className={
              error.dismissable === undefined || error.dismissable
                ? 'col-9'
                : 'col-12'
            }
          >
            <div className={'row'}>
              <div className={'col-12 fs-4 col color-alert'}>{error.title}</div>
              {/*<div className={'col-4 pt-1 fs-caption text-end'}>*/}
              {/*  {error.occurred_on || formatTimeAndDate(new Date(Date.now()))}*/}
              {/*</div>*/}
            </div>
            <div className={'row'}>
              <div className={'col-12'}>{error.message}</div>
            </div>
          </div>
          {(error.dismissable === undefined || error.dismissable) &&
            typeof onAcknowledge === 'function' && (
              <div className={'col-3'}>
                <Button
                  onClick={() => {
                    onAcknowledge(error);
                    if (typeof onClose === 'function') {
                      onClose(error);
                    }
                  }}
                >
                  Fehler Quittieren
                </Button>
              </div>
            )}
        </div>
      </div>
    </div>
  </Alert>
);
