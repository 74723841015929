import './_analysis-chart.scss';

import { max, maxBy, minBy } from 'lodash';
import React, { ComponentType, useEffect, useRef, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const saveSvgAsPng = require('save-svg-as-png');
import { Button, Spinner } from 'react-bootstrap';
import {
  createContainer,
  DomainTuple,
  VictoryArea,
  VictoryAxis,
  VictoryBrushContainer,
  VictoryChart,
  VictoryLine,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainerProps,
  VictoryZoomContainerProps,
} from 'victory';

import {
  formatTimeAndDateForFilename,
  formatTimeWithSecondsAndDate,
  formatWithFullDateAt0,
} from '../../helpers/date-helpers';
import { nFormatter } from '../../helpers/number-helpers';
const WARNING_AREA_COLOR = '#FFDE0333';
const CRITICAL_AREA_COLOR = '#B0002033';

export type AnalysisDataSeries = {
  name: string;
  color: string;
  datapoints: { x: Date; y: number }[];
};

export type AnalysisChartProps = {
  width: number;
  warning: number;
  critical: number;
  data: AnalysisDataSeries[];
  label?: string;
  unit?: string;
  start: number;
  end: number;
  isLoading?: boolean;
};

// eslint-disable-next-line react/prop-types
export default function AnalysisChart({
  width,
  warning,
  critical,
  data,
  label,
  unit,
  start,
  end,
  isLoading = false,
}: AnalysisChartProps) {
  const [selectedDomain, setSelectedDomain] = useState<{
    x: DomainTuple;
    y: DomainTuple;
  }>();
  const [zoomDomain, setZoomDomain] = useState<{
    x: DomainTuple;
    y: DomainTuple;
  }>();

  const imageOptions = {
    scale: 5,
    encoderOptions: 1,
    backgroundColor: 'white',
  };

  const VictoryZoomVoronoiContainer: ComponentType<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any & VictoryZoomContainerProps & VictoryVoronoiContainerProps
  > = createContainer('zoom', 'voronoi') as React.ComponentType<
    VictoryZoomContainerProps & VictoryVoronoiContainerProps
  >;

  const [minMaxY, setMinMaxY] = useState<[number, number]>([0, 1000]);

  const zoomContainerRef = useRef<typeof VictoryZoomVoronoiContainer>(null);

  /// EFFECTS
  useEffect(() => {
    let maxYValue = 0;

    for (let i = 0; i < data.length; i++) {
      const tmpMax = maxBy(data[i].datapoints, (o) => o.y)?.y;
      if (tmpMax && tmpMax > maxYValue) {
        maxYValue = tmpMax;
      }
    }

    setMinMaxY([0, maxYValue]);
    let minXValue: Date | undefined;

    for (let i = 0; i < data.length; i++) {
      const tmpMin = minBy(data[i].datapoints, (o) => o.x)?.x.valueOf();
      if (
        (tmpMin && !minXValue) ||
        (tmpMin && minXValue && tmpMin < minXValue.valueOf())
      ) {
        minXValue = new Date(tmpMin);
      }
    }

    let maxXValue: Date | undefined;

    for (let i = 0; i < data.length; i++) {
      const tmpMax = maxBy(data[i].datapoints, (o) => o.x)?.x.valueOf();
      if (
        (tmpMax && !maxXValue) ||
        (tmpMax && maxXValue && tmpMax > maxXValue.valueOf())
      ) {
        maxXValue = new Date(tmpMax);
      }
    }

    if (!minXValue) {
      minXValue = new Date(0);
    }

    if (!maxXValue) {
      maxXValue = new Date(Date.now());
    }

    setZoomDomain({ x: [minXValue, maxXValue], y: [0, maxYValue * 1.2] });
  }, [data]);

  /// HANDLERS

  // Triggered by onZoomDomainChange and
  // alters VictoryBrushContainer brushDomain prop
  const handleZoom = (domain: { x: DomainTuple; y: DomainTuple }) => {
    setSelectedDomain(domain);
  };

  // Triggered by onBrushDomainChange and
  // alters VictoryZoomContainer zoomDomain prop
  const handleBrush = (domain: { x: DomainTuple; y: DomainTuple }) => {
    let maxY = 0;
    for (let i = 0; i < data.length; i++) {
      const tmpDomain = data[i].datapoints.filter(
        (value) => value.x > domain.x[0] && value.x < domain.x[1],
      );
      const tmpMaxY =
        maxBy(tmpDomain, (o) => o.y)?.y.valueOf() ||
        max([warningUpper, warningLower, criticalUpper, criticalLower]) ||
        1000;
      if (tmpMaxY > maxY) {
        maxY = tmpMaxY;
      }
    }

    setZoomDomain({ x: domain.x, y: [0, maxY * 1.2] });
  };

  const warningLower = warning < critical ? warning : critical;
  const warningUpper = warning < critical ? critical : warning;

  const criticalLower = warning < critical ? critical : 0;
  const criticalUpper = warning < critical ? Number.MAX_SAFE_INTEGER : critical;

  const warningArea = () => {
    if (
      !data ||
      data.length === 0 ||
      !data[0].datapoints ||
      data[0].datapoints.length === 0
    ) {
      return [];
    }
    return [
      {
        x: data[0].datapoints[0].x,
        y: warningUpper,
        y0: warningLower,
      },
      {
        x: data[0].datapoints[data[0].datapoints.length - 1].x,
        y: warningUpper,
        y0: warningLower,
      },
    ];
  };

  const criticalArea = () => {
    if (
      !data ||
      data.length === 0 ||
      !data[0].datapoints ||
      data[0].datapoints.length === 0
    ) {
      return [];
    }
    return [
      {
        x: data[0].datapoints[0].x,
        y: criticalUpper,
        y0: criticalLower,
      },
      {
        x: data[0].datapoints[data[0].datapoints.length - 1].x,
        y: criticalUpper,
        y0: criticalLower,
      },
    ];
  };

  const saveAsPng = () => {
    if (!zoomContainerRef.current) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const container = zoomContainerRef.current?.containerRef.children.item(0);
    saveSvgAsPng.saveSvgAsPng(
      container,
      `${label}(${unit})-${formatTimeAndDateForFilename(
        new Date(start * 1000),
      )}-${formatTimeAndDateForFilename(new Date(end * 1000))}.png`,
      imageOptions,
    );
  };

  if (isLoading || !width) {
    return (
      <div className={'analysis-chart'}>
        <div className={'row align-content-center chart-height'}>
          <div className={'col-12'}>
            <div className={'pb-4 fs-5 text-center'}>
              {label} Daten werden geladen...
            </div>
            <div className={'pb-4 fs-5 text-center'}>
              <Spinner animation="grow" variant={'primary'} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const hasData = () => {
    if (data.length === 0) return false;

    for (let i = 0; i < data.length; i++) {
      if (data[i].datapoints.length > 0) {
        return true;
      }
    }

    return false;
  };

  if (
    !hasData() ||
    !zoomDomain ||
    (zoomDomain.y[0] === 0 && zoomDomain.y[1] === 0)
  ) {
    return (
      <div className={'analysis-chart'}>
        <div className={'row align-content-center chart-height'}>
          <div className={'col-12'}>
            <div className={'pb-4 fs-5 text-center'}>
              Für diesen Zeitraum sind keine {label} Daten verfügbar.
            </div>
          </div>
        </div>
      </div>
    );
  }

  const formatTooltipName = (datum: {
    x: Date;
    y: number;
    childName: string;
  }) => {
    const name =
      datum.childName.charAt(0).toUpperCase() + datum.childName.slice(1);

    if (name === 'Ground') {
      return 'Continuity';
    }

    return name;
  };

  return (
    <div>
      {label && (
        <div className={'row'}>
          <div className={'col-12 justify-content-center'}>
            <div className={'fs-4 text-center'}>{label}</div>
          </div>
        </div>
      )}
      <VictoryChart
        width={width - 40}
        height={350}
        scale={{ x: 'time', y: 'linear' }}
        theme={VictoryTheme.material}
        padding={{ top: 0, left: 60, right: 50, bottom: 30 }}
        containerComponent={
          <VictoryZoomVoronoiContainer
            voronoiDimension="x"
            labels={({
              datum,
            }: {
              datum: { x: Date; y: number; childName: string };
            }) =>
              `${formatTooltipName(datum)}: ${nFormatter(
                datum.y,
                2,
                unit,
              )} - ${formatTimeWithSecondsAndDate(datum.x)}`
            }
            labelComponent={
              <VictoryTooltip
                constrainToVisibleArea={true}
                cornerRadius={4}
                style={{ fontSize: 18 }}
                flyoutStyle={{ fill: 'white' }}
              />
            }
            ref={zoomContainerRef}
            responsive={false}
            zoomDimension="x"
            zoomDomain={zoomDomain}
            allowZoom={false}
            onZoomDomainChange={handleZoom}
          />
        }
      >
        <VictoryAxis
          dependentAxis={false}
          tickFormat={(x) => formatWithFullDateAt0(x)}
        />
        <VictoryAxis
          dependentAxis
          crossAxis
          width={width - 40}
          theme={VictoryTheme.material}
          offsetX={60}
          tickFormat={(x: number) => nFormatter(x, 2, unit)}
        />

        <VictoryArea
          name={'Warning'}
          style={{ data: { fill: WARNING_AREA_COLOR } }}
          data={warningArea()}
          domain={{ y: [0, minMaxY[1]] }}
        />

        <VictoryArea
          name={'Critical'}
          style={{ data: { fill: CRITICAL_AREA_COLOR } }}
          data={criticalArea()}
          domain={{ y: [0, minMaxY[1]] }}
        />

        {data.length > 0 &&
          data.map((series) => (
            <VictoryLine
              name={series.name}
              key={`victory-line-${series.name}`}
              style={{
                data: { stroke: series.color },
              }}
              data={series.datapoints}
              interpolation={'linear'}
            />
          ))}
      </VictoryChart>

      <VictoryChart
        padding={{ top: 0, left: 60, right: 50, bottom: 30 }}
        width={width - 40}
        height={90}
        scale={{ x: 'time', y: 'linear' }}
        containerComponent={
          <VictoryBrushContainer
            responsive={false}
            brushDimension="x"
            brushDomain={selectedDomain}
            onBrushDomainChange={handleBrush}
          />
        }
      >
        <VictoryAxis tickFormat={(x) => formatWithFullDateAt0(x)} />

        {data.length > 0 &&
          data.map((series) => (
            <VictoryLine
              key={`victory-line-brush-${series.name}`}
              interpolation={'linear'}
              style={{
                data: { stroke: series.color },
              }}
              data={series.datapoints}
            />
          ))}
      </VictoryChart>
      <div className={'row'}>
        <div className={'col-12  justify-content-end text-end'}>
          <Button className={'small'} onClick={() => saveAsPng()}>
            Als Bild speichern
          </Button>
        </div>
      </div>
    </div>
  );
}
