import 'react-confirm-alert/src/react-confirm-alert.css';
import './../../common.scss';
import './_user-list.scss';

import { ValidationMap } from 'prop-types';
import React, { useEffect, useReducer, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';

import { Role } from '../../providers/auth-provider/auth-provider';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  createUser,
  deleteUser,
  loadAllUsers,
  saveUser,
} from '../../store/user';
import { UserState } from '../../store/user/types';
import UserItem from '../user-item/user-item';

const formReducer = (
  state: { [key: string]: string | number | Date },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  event: any,
) => {
  return {
    ...state,
    [event.target.name]: event.target.value,
  };
};

export type ACLValue = { read?: boolean; write?: boolean };

export type User = {
  id?: ValidationMap<string>;
  username?: string;
  password?: string;
  roles?: Role[];
  state?: string;
  updatedAt?: string;
};

export default function UserList() {
  const { status, users, error } = useAppSelector(
    (state: { user: UserState }) => state.user,
  );

  const [formData, setFormData] = useReducer(formReducer, {});
  const [_showCreateUser, setShowCreateUser] = useState<boolean>(false);
  const [_createdUser, setCreatedUser] = useState<User>({});

  const dispatch = useAppDispatch();

  // Trigger Loading Users
  useEffect(() => {
    dispatch(loadAllUsers());
  }, []);

  const save = () => {
    const nextUser = Object.assign({}, _createdUser, formData);
    if (nextUser.id) {
      if (typeof saveUser === 'function') {
        dispatch(saveUser(nextUser));
      }
    } else {
      if (typeof createUser === 'function') {
        dispatch(createUser(nextUser));
      }
    }
  };

  const handleShowCreateUser = () => {
    setShowCreateUser(true);
  };

  const handleCancelCreateUser = () => {
    setCreatedUser({});
    setShowCreateUser(false);
  };

  const handleSaveCreateUser = () => {
    save();
    setCreatedUser({});
    setShowCreateUser(false);
  };

  const handleRoleChange = ({
    target: { name, checked },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const nextUser = Object.assign({}, _createdUser);
    if (!nextUser.roles) {
      nextUser.roles = [];
    }

    const roleName = name as keyof typeof Role;

    if (checked && !nextUser.roles.includes(Role[roleName])) {
      nextUser.roles.push(Role[roleName]);
    }

    if (!checked && nextUser.roles.includes(Role[roleName])) {
      nextUser.roles.splice(nextUser.roles.indexOf(Role[roleName], 1));
    }

    setCreatedUser(nextUser);
  };

  const confirmDeleteUser = (user: User) => {
    confirmAlert({
      title: 'Bestätigen',
      message: 'Willst du den Nutzer wirklich löschen?',
      buttons: [
        {
          label: 'Ja, löschen',
          onClick: () => dispatch(deleteUser(user)),
          className: 'button-yes',
        },
        {
          label: 'Nein, doch nicht',
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClick: () => {},
          className: 'button-no',
        },
      ],
    });
  };

  if (status === 'loading') {
    return <div className="user-list">loading</div>;
  }

  if (!users || users.length === 0) {
    return (
      <>
        <div className="user-list">empty</div>
        <Button onClick={handleShowCreateUser}>Nutzer erstellen</Button>
      </>
    );
  }

  return (
    <div className="user-list">
      <div className="row">
        <div className="col-12">
          <div className="fs-4">User-Management</div>
        </div>
      </div>
      <div className="row">
        <div className="col-8 text-danger">{error && <span>{error}</span>}</div>
        <div className="col-4 text-end">
          <Button onClick={handleShowCreateUser}>Nutzer erstellen</Button>
        </div>
      </div>
      <div className="row">
        <table className="table" width="90vw">
          <thead>
            <tr>
              <th scope="col">Benutzername</th>
              {(Object.keys(Role) as Array<keyof typeof Role>).map((key) => (
                <th scope="col" key={key} className="text-capitalize">
                  {key}
                </th>
              ))}
              <th scope="col">Aktualisiert</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user: User) => (
              <UserItem
                key={user.id}
                user={user}
                saveUser={(user) => dispatch(saveUser(user))}
                deleteUser={(user) => confirmDeleteUser(user)}
              />
            ))}
          </tbody>
        </table>
      </div>
      <Modal show={_showCreateUser} onHide={handleCancelCreateUser}>
        <Modal.Header closeButton>
          <Modal.Title>Neuer Nutzer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className={'w-100'}>
            <tbody>
              <tr>
                <td>Benutzername</td>
                <td colSpan={Object.keys(Role).length}>
                  <input
                    type="text"
                    className="form-control text-decoration-underline"
                    placeholder=""
                    aria-label="Benutzername"
                    aria-describedby="basic-addon1"
                    defaultValue={_createdUser.password}
                    onChange={setFormData}
                    name="username"
                  />
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>Passwort</td>
                <td colSpan={Object.keys(Role).length}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    aria-label="Passwort"
                    aria-describedby="basic-addon1"
                    defaultValue={_createdUser.password}
                    onChange={setFormData}
                    name="password"
                  />
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>Rollen</td>
                <td>
                  <table className="w-100">
                    <thead>
                      <tr>
                        {(Object.keys(Role) as Array<keyof typeof Role>).map(
                          (key) => (
                            <th key={'newuser-' + key} style={{ width: `25%` }}>
                              {key}
                            </th>
                          ),
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {(Object.keys(Role) as Array<keyof typeof Role>).map(
                          (key) => (
                            <td
                              key={'newuser-role-' + key}
                              style={{ width: `25%` }}
                            >
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  name={key}
                                  onChange={handleRoleChange}
                                  checked={
                                    !!_createdUser.roles?.includes(
                                      Role[key as keyof typeof Role],
                                    )
                                  }
                                />
                              </div>
                            </td>
                          ),
                        )}
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelCreateUser}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={handleSaveCreateUser}>
            Speichern
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
