import './_live.scss';

import React, { useEffect } from 'react';

import { useResize } from '../../helpers/use-resize';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { loadSettings } from '../../store/settings';
import {
  SettingsState,
  SettingType,
  SystemSettings,
} from '../../store/settings/types';
import { renderAlert } from '../../stories/error-messages/error-item';
import OvenLive from '../../stories/oven-live/oven-live';

// eslint-disable-next-line @typescript-eslint/ban-types
export type LiveProps = {};

// eslint-disable-next-line no-empty-pattern
export default function Live({}: LiveProps) {
  const dispatch = useAppDispatch();

  // Trigger Load Settings
  useEffect(() => {
    dispatch(loadSettings());
  }, []);

  /// SELECTORS

  const settingsLoadError: string | null | undefined = useAppSelector(
    (state: { settings: SettingsState }) => {
      return state.settings.error;
    },
  );

  const selectCountOvens: number = useAppSelector(
    (state: { settings: SettingsState }) => {
      const ovenCountSetting = state.settings.settings.find(
        (setting) => setting.name === SystemSettings.ovens,
      );

      if (!ovenCountSetting) {
        return 0;
      }

      return ovenCountSetting.valueWithType.value as number;
    },
  );

  const selectOvenConfigSettings: { [key: string]: SettingType[] } =
    useAppSelector((state: { settings: SettingsState }) => {
      const nextOvenSettings: { [key: string]: SettingType[] } = {};
      if (!state.settings.settingsByCategory || !selectCountOvens) {
        return nextOvenSettings;
      }

      for (let i = 0; i < selectCountOvens; i++) {
        const ovenKey = `oven-config-${i}`;
        nextOvenSettings[i] = state.settings.settingsByCategory[ovenKey];
      }

      return nextOvenSettings;
    });

  /// Prepare settings for each oven
  const selectOvenGuiSettings: { [key: string]: SettingType[] } =
    useAppSelector((state: { settings: SettingsState }) => {
      const nextOvenSettings: { [key: string]: SettingType[] } = {};
      if (!state.settings.settingsByCategory || !selectCountOvens) {
        return nextOvenSettings;
      }

      for (let i = 0; i < selectCountOvens; i++) {
        const ovenKey = `gui-oven-${i}`;
        nextOvenSettings[i] = state.settings.settingsByCategory[ovenKey];
      }

      return nextOvenSettings;
    });

  const selectLiveViewSettings = useAppSelector(
    (state: { settings: SettingsState }) => {
      if (!state.settings.settings) {
        return [];
      }
      return state.settings.settingsByCategory['live-view'];
    },
  );

  const size = useResize();

  return (
    <div className="live">
      {settingsLoadError &&
        renderAlert({
          error: {
            title: 'Fehler',
            message: settingsLoadError,
            key: 'setting_critical',
            dismissable: false,
          },
        })}
      <div className="fs-2" style={{ height: `${size.height / 12}px` }}>
        Live
      </div>
      <div className={'row'} style={{ height: `${(size.height / 12) * 11}px` }}>
        {Object.keys(selectOvenConfigSettings).map((key) => (
          <div key={key} className={`col-${12 / selectCountOvens}`}>
            <OvenLive
              name={key}
              config={selectOvenConfigSettings[key]}
              gui={selectOvenGuiSettings[key]}
              liveView={selectLiveViewSettings}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
