import './App.scss';

import { useParseQuery } from '@parse/react';
import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import logo_ews from './assets/logo-ews.png';
import { ProtectedComponent } from './components/protected-route/protected-component';
import { Sidebar } from './components/sidebar/sidebar';
import { useResize } from './helpers/use-resize';
import History from './pages/history/history';
import Live from './pages/live/live';
import { LoginPage } from './pages/login-page/login-page';
import { SettingsPage } from './pages/settings-page/settings-page';
import { Role, useAuth } from './providers/auth-provider/auth-provider';
import ErrorMessages from './stories/error-messages/error-messages';

// eslint-disable-next-line @typescript-eslint/ban-types
export type AppProps = {};

// eslint-disable-next-line no-empty-pattern
export default function App({}: AppProps) {
  const location = useLocation();
  const auth = useAuth();

  const licenseValidQuery = new Parse.Query('Setting').equalTo(
    'name',
    'licenceValidUntil',
  );

  const { results } = useParseQuery(licenseValidQuery, {
    enableLiveQuery: true,
  });

  if (results && results?.length > 0) {
    const licenseValidUntilSetting = results[0];
    const licenseValidUntilMillis: number = Date.parse(
      licenseValidUntilSetting.attributes.valueWithType.value as string,
    );

    const licenseValid = !licenseValidUntilMillis
      ? true
      : Date.now() < licenseValidUntilMillis;

    if (!licenseValid) {
      if (Parse.User.current()?.getUsername() !== 'admin') {
        auth.onLogout();
      }
    }
  }

  return (
    <div className="container-fluid">
      <div className="row flex-nowrap">
        {location.pathname !== '/login' && (
          <div className="col-auto min-vh-100 App-sidebar">
            <Sidebar />
          </div>
        )}
        <div className="col">
          <div className="row flex-nowrap">
            {location.pathname !== '/login' && (
              <div className="col-auto min-vh-100 App-sidebar-dummy">
                &nbsp;
              </div>
            )}
            <div className="col">
              {location.pathname !== '/login' && <Header />}
              <EWSRoutes />
              {location.pathname !== '/login' && <ErrorMessages />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const EWSRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={<LoginPage />} />
      <Route
        index
        element={
          <ProtectedComponent requiredRoles={[Role['live']]}>
            <Live />
          </ProtectedComponent>
        }
      />
      <Route
        path="history"
        element={
          <ProtectedComponent requiredRoles={[Role['analyse']]}>
            <History />
          </ProtectedComponent>
        }
      />
      <Route
        path="settings"
        element={
          <ProtectedComponent
            requiredRoles={[Role['settings'], Role['service']]}
          >
            <SettingsPage />
          </ProtectedComponent>
        }
      />

      <Route
        path="*"
        element={
          <ProtectedComponent requiredRoles={[Role['live']]}>
            <Live />
          </ProtectedComponent>
        }
      />
    </Routes>
  );
};

const Header = () => {
  const size = useResize();
  return (
    <div className="App-header">
      <div className="pt-2">
        <img
          src={logo_ews}
          alt="EWS Control GmbH"
          height={`${size.height / 12}px`}
        />
      </div>
    </div>
  );
};
