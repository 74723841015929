import './_history.scss';
import 'react-datepicker/dist/react-datepicker.css';

import { InfluxDB, QueryApi } from '@influxdata/influxdb-client';
import { useParseQuery } from '@parse/react';
import de from 'date-fns/locale/de';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { Button, Dropdown, DropdownButton, Spinner } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { CSVLink } from 'react-csv';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { FaTrash } from 'react-icons/fa';

import {
  dateTimeFormatString,
  formatTimeAndDate,
} from '../../helpers/date-helpers';
import { useResize } from '../../helpers/use-resize';
import { useParse } from '../../providers/parse-provider/parse-provider';
import formReducer from '../../store/form-reducer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { loadSettings } from '../../store/settings';
import {
  selectOvenConfigValue,
  selectSystemConfigValue,
} from '../../store/settings/selectors';
import { SettingsState } from '../../store/settings/types';
import AnalysisChart, {
  AnalysisDataSeries,
} from '../../stories/analysis-chart/analysis-chart';
import { influxExportQuery, influxQuery, queryRows } from './queries';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
registerLocale('de-DE', de);
setDefaultLocale('de-DE');

export const WEEK_SECONDS = 604800;
export const HOUR_SECONDS = 3600;

type ExportData = {
  // [key: string]: unknown;
  time: number;
  time_str: string;
  channel?: string;
  count_energy_min?: string;
  count_energy_max?: string;
  count_energy_median?: string;
  resistance_continuitytest_min: string;
  resistance_continuitytest_max: string;
  resistance_continuitytest_median: string;
  resistance_wear_min: string;
  resistance_wear_max: string;
  resistance_wear_median: string;
  trend_wear_min: string;
  trend_wear_max: string;
  trend_wear_median: string;
};

export type DisplayData = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  x: Date;
  y: number;
};

const initialFormState = {
  oven: 0,
  start: Date.now() - 2 * WEEK_SECONDS,
  end: Date.now(),
  toggle_wear: true,
  toggle_trend: true,
  toggle_ground: true,
  toggle_power: true,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type HistoryProps = {};

// eslint-disable-next-line no-empty-pattern
export default function History({}: HistoryProps) {
  const Parse = useParse();
  const size = useResize();
  const ref = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const csv5MinRef = useRef<any | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const csv1MinRef = useRef<any | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const csv1SecRef = useRef<any | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [csvRef, setCsvRef] = useState<React.MutableRefObject<any>>();
  const dispatch = useAppDispatch();

  // const [data, setData] = useState<DisplayData[]>([]);
  const [wearData, setWearData] = useState<DisplayData[]>([]);
  const [groundData, setGroundData] = useState<DisplayData[]>([]);
  const [powerData, setPowerData] = useState<DisplayData[]>([]);
  const [trendData, setTrendData] = useState<DisplayData[]>([]);
  const [formData, localDispatch] = useReducer(formReducer, initialFormState);
  const [graphWidth, setGraphWidth] = useState<number>(0);
  const [influxQueryApi, setInfluxQueryApi] = useState<QueryApi | undefined>(
    undefined,
  );
  const [medianData, setMedianData] = useState<DisplayData[]>([]);
  const [minData, setMinData] = useState<DisplayData[]>([]);
  const [maxData, setMaxData] = useState<DisplayData[]>([]);
  const [exportData, setExportData] = useState<ExportData[]>([]);
  const [isExporting, setExporting] = useState<boolean>(false);
  const [isWearLoading, setIsWearLoading] = useState<boolean>(false);
  const [isTrendLoading, setIsTrendLoading] = useState<boolean>(false);
  const [isContinuityLoading, setIsContinuityLoading] =
    useState<boolean>(false);
  const [isPowerLoading, setIsPowerLoading] = useState<boolean>(false);
  const [selectedOvenJourney, setSelectedOvenJourney] =
    useState<Parse.Object>();

  /// SELECTORS

  const influxToken: string = useAppSelector(
    (state: { settings: SettingsState }) =>
      selectSystemConfigValue(state, 'influx-token') as string,
  );

  const influxOrg: string = useAppSelector(
    (state: { settings: SettingsState }) =>
      selectSystemConfigValue(state, 'influx-org') as string,
  );

  const influxUrl: string = useAppSelector(
    (state: { settings: SettingsState }) =>
      selectSystemConfigValue(state, 'influx-url') as string,
  );

  const ovenLabel = `Ofen ${Number.parseInt(formData['oven']) + 1}`;

  const liveOvenJourneyQuery = new Parse.Query('OvenJourney')
    .equalTo('oven', Number.parseInt(formData['oven']))
    .ascending('start')
    .addAscending('start');

  const { results, reload, isLoading } = useParseQuery(liveOvenJourneyQuery, {
    enableLiveQuery: true,
  });

  const isOvenJourneyRunning = () => {
    if (results && results[results.length - 1]) {
      return results[results.length - 1].attributes.end === undefined;
    }

    return false;
  };

  //// CALLBACKS
  const saveOvenJourney = useCallback(
    async ({ isRunning }: { isRunning?: boolean }) => {
      if (isRunning && selectedOvenJourney) {
        selectedOvenJourney.set('end', new Date(formData['date-end'] * 1000));
        selectedOvenJourney.set(
          'name',
          `Ofenreise ${
            Number.parseInt(formData['oven']) + 1
          } vom ${formatTimeAndDate(
            selectedOvenJourney.attributes.start,
          )} bis ${formatTimeAndDate(new Date(formData['date-end'] * 1000))}`,
        );
        await selectedOvenJourney.save();
      } else {
        const journey = new Parse.Object('OvenJourney');
        journey.set(
          'name',
          `Aktive Ofenreise vom ${formatTimeAndDate(
            new Date(formData['date-start'] * 1000),
          )}`,
        );
        journey.set('start', new Date(formData['date-start'] * 1000));
        journey.set('oven', Number.parseInt(formData['oven']));
        await journey.save();
        setSelectedOvenJourney(journey);
      }

      reload();
    },
    [
      formData['date-start'],
      formData['date-end'],
      formData['oven'],
      selectedOvenJourney,
    ],
  );

  const onDeleteJourney = () => {
    if (selectedOvenJourney) {
      try {
        selectedOvenJourney.destroy();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Ofenreise kann nicht gelöscht werden', e);
      }

      setSelectedOvenJourney(undefined);
    }
  };

  const selectedJourneyIndex = (): number => {
    if (!results || results.length === 0) {
      return -1;
    }
    if (selectedOvenJourney) {
      return results.findIndex((res) => res.id === selectedOvenJourney.id);
    }

    return -1;
  };

  const hasPrevJourney = () => {
    if (!results || results.length === 0) {
      return false;
    }
    return selectedJourneyIndex() > 0;
  };

  const hasNextJourney = () => {
    if (!results || results.length < 2) {
      return;
    }
    return selectedJourneyIndex() < results.length - 1;
  };

  const selectPreviousJourney = () => {
    if (!results || results.length === 0) {
      return;
    }

    if (selectedOvenJourney) {
      if (selectedJourneyIndex() > 0 && results && results.length > 1) {
        setSelectedOvenJourney(results[selectedJourneyIndex() - 1]);
      }
    }
  };
  const selectNextJourney = () => {
    if (!results || results.length < 2) {
      return;
    }

    if (selectedOvenJourney) {
      if (selectedJourneyIndex() < results.length - 1) {
        setSelectedOvenJourney(results[selectedJourneyIndex() + 1]);
      }
    }
  };

  const sortAscByStartAttribute = (a: Parse.Object, b: Parse.Object) => {
    const aStart = a.attributes.start.valueOf();
    const bStart = b.attributes.start.valueOf();
    return aStart - bStart;
  };

  //// EFFECTS

  useEffect(() => {
    dispatch(loadSettings());
  }, []);

  useEffect(() => {
    setSelectedOvenJourney(undefined);
  }, [formData['oven']]);

  useEffect(() => {
    const isLoading =
      isContinuityLoading || isPowerLoading || isTrendLoading || isWearLoading;
    if (isLoading) {
      return;
    }

    if (selectedOvenJourney) {
      localDispatch({
        type: 'HANDLE_INPUT_TEXT',
        field: 'date-start',
        payload: Math.round(
          selectedOvenJourney.attributes.start.valueOf() / 1000,
        ),
      });

      if (selectedOvenJourney.attributes.end) {
        localDispatch({
          type: 'HANDLE_INPUT_TEXT',
          field: 'date-end',
          payload: Math.round(
            selectedOvenJourney?.attributes.end.valueOf() / 1000,
          ),
        });
      } else {
        localDispatch({
          type: 'HANDLE_INPUT_TEXT',
          field: 'date-end',
          payload: Math.round(Date.now() / 1000),
        });
      }
    }
  }, [selectedOvenJourney]);

  useEffect(() => {
    if (!results) {
      return;
    }
    if (results.length === 0) {
      if (!formData['date-start']) {
        localDispatch({
          type: 'HANDLE_INPUT_TEXT',
          field: 'date-start',
          payload: Math.round((Date.now() - 1209600000) / 1000),
        });
      }

      if (!formData['date-end']) {
        localDispatch({
          type: 'HANDLE_INPUT_TEXT',
          field: 'date-end',
          payload: Math.round(Date.now() / 1000),
        });
      }
      setSelectedOvenJourney(undefined);
    }

    if (!selectedOvenJourney && results && results.length > 0) {
      const sorted = results.sort(sortAscByStartAttribute);
      setSelectedOvenJourney(sorted[sorted.length - 1]);
    }
  }, [results?.length]);

  useLayoutEffect(() => {
    if (ref.current?.offsetWidth) {
      setGraphWidth(ref.current?.offsetWidth);
    }
  }, [ref.current]);

  useEffect(() => {
    if (!influxOrg || !influxToken || !influxUrl) {
      return;
    }
    setInfluxQueryApi(
      new InfluxDB({
        url: influxUrl,
        token: influxToken,
      }).getQueryApi(influxOrg),
    );
  }, [influxOrg, influxToken, influxUrl]);

  useEffect(() => {
    const isLoading =
      isContinuityLoading || isPowerLoading || isTrendLoading || isWearLoading;
    if (isLoading) {
      return;
    }
    const start = formData['date-start'];
    const end = formData['date-end'];

    if (!influxUrl || !influxQueryApi || !ovenLabel || !start || !end) {
      return;
    }

    if (end - start <= 0) {
      return;
    }

    const wearQuery = influxQuery({
      ovens: [Number.parseInt(formData['oven']) + 1],
      measurements: ['Wear'],
      fields: ['Resistance'],
      start: formData['date-start'],
      end: formData['date-end'],
      period: 'auto',
      bucket: influxBucket,
    });
    queryRows({
      api: influxQueryApi,
      query: wearQuery,
      setter: setWearData,
      isExport: false,
      loadingSetter: setIsWearLoading,
      setExporting,
    });

    const trendData = influxQuery({
      ovens: [Number.parseInt(formData['oven']) + 1],
      measurements: ['Wear'],
      fields: ['Trend'],
      start: formData['date-start'],
      end: formData['date-end'],
      period: 'auto',
      bucket: influxBucket,
    });
    queryRows({
      api: influxQueryApi,
      query: trendData,
      setter: setTrendData,
      isExport: false,
      loadingSetter: setIsTrendLoading,
      setExporting,
    });

    const continuityQuery = influxQuery({
      ovens: [Number.parseInt(formData['oven']) + 1],
      measurements: ['Continuitytest'],
      fields: ['Resistance'],
      start: formData['date-start'],
      end: formData['date-end'],
      period: 'auto',
      bucket: influxBucket,
    });
    queryRows({
      api: influxQueryApi,
      query: continuityQuery,
      setter: setGroundData,
      isExport: false,
      loadingSetter: setIsContinuityLoading,
      setExporting,
    });

    const powerQuery = influxQuery({
      ovens: [Number.parseInt(formData['oven']) + 1],
      measurements: ['Energy'],
      fields: ['Count'],
      start: formData['date-start'],
      end: formData['date-end'],
      period: 'auto',
      bucket: influxBucket,
    });

    queryRows({
      api: influxQueryApi,
      query: powerQuery,
      setter: setPowerData,
      isExport: false,
      loadingSetter: setIsPowerLoading,
      setExporting,
    });
  }, [influxQueryApi, ovenLabel, formData['date-start'], formData['date-end']]);

  useEffect(() => {
    if (medianData.length > 0 && minData.length > 0 && maxData.length) {
      const _exportData: ExportData[] = [];
      // CH
      // Count_Energy
      // Resistance_Continuitytest
      // Resistance_Wear
      // Trend_Wear
      for (let i = 0; i < maxData.length; i++) {
        _exportData.push({
          time: maxData[i]['x'].valueOf(),
          time_str: new Date(maxData[i]['x']).toString(),
          channel: maxData[i]['CH'],
          count_energy_min: minData[i]['Count_Energy'],
          count_energy_max: maxData[i]['Count_Energy'],
          count_energy_median: medianData[i]['Count_Energy'],
          resistance_continuitytest_min:
            minData[i]['Resistance_Continuitytest'],
          resistance_continuitytest_max:
            maxData[i]['Resistance_Continuitytest'],
          resistance_continuitytest_median:
            medianData[i]['Resistance_Continuitytest'],
          resistance_wear_min: minData[i]['Resistance_Wear'],
          resistance_wear_max: maxData[i]['Resistance_Wear'],
          resistance_wear_median: medianData[i]['Resistance_Wear'],
          trend_wear_min: minData[i]['Trend_Wear'],
          trend_wear_max: maxData[i]['Trend_Wear'],
          trend_wear_median: medianData[i]['Trend_Wear'],
        });
      }

      setExportData(_exportData);
    }
  }, [medianData, minData, maxData]);

  useEffect(() => {
    if (
      exportData.length > 0 &&
      csvRef &&
      csvRef.current &&
      csvRef.current.link
    ) {
      setTimeout(() => {
        csvRef.current.link.click();
        setExportData([]);
        setMaxData([]);
        setMinData([]);
        setMedianData([]);
        setExporting(false);
      });
    }
  }, [exportData]);

  // HANDLERS
  const onChange = (event: React.MouseEvent<HTMLElement>) => {
    const target: { id: string; target: number; textContent: string } =
      event.target as unknown as {
        id: string;
        target: number;
        textContent: string;
      };
    localDispatch({
      type: 'HANDLE_INPUT_TEXT',
      field: target.id,
      payload: target.target || target.textContent,
    });
  };

  const onToggle = (event: React.ChangeEvent<HTMLElement>) => {
    const target = event.target as HTMLInputElement;
    localDispatch({
      type: 'HANDLE_INPUT_TEXT',
      field: target.id,
      payload: target.checked,
    });
  };

  const onDateChange = ({
    date,
    property,
  }: {
    date?: Date | null;
    property: string;
  }) => {
    if (!date) {
      return;
    }

    localDispatch({
      type: 'HANDLE_INPUT_TEXT',
      field: property,
      payload: date.valueOf() / 1000,
    });
  };

  /// SELECTORS

  const influxBucket: string = useAppSelector(
    (state: { settings: SettingsState }) =>
      selectSystemConfigValue(state, 'influx-bucket') as string,
  );

  const ovenGroundWarning: number = useAppSelector(
    (state: { settings: SettingsState }) =>
      selectOvenConfigValue(
        state,
        formData['oven'],
        'threshold_ground_advance',
      ),
  );

  const ovenGroundCritical: number = useAppSelector(
    (state: { settings: SettingsState }) =>
      selectOvenConfigValue(
        state,
        formData['oven'],
        'threshold_ground_critical',
      ),
  );

  const ovenMeltWarning: number = useAppSelector(
    (state: { settings: SettingsState }) =>
      selectOvenConfigValue(
        state,
        formData['oven'],
        'threshold_melt_advance',
      ) as number,
  );

  const ovenMeltCritical: number = useAppSelector(
    (state: { settings: SettingsState }) =>
      selectOvenConfigValue(state, formData['oven'], 'threshold_melt_critical'),
  );

  const countOvens: number = useAppSelector(
    (state: { settings: SettingsState }) =>
      selectSystemConfigValue(state, 'ovens') as number,
  );

  const ovens = [];
  for (let i = 0; i < countOvens; i++) {
    ovens.push(i);
  }

  const prepareCsvData = ({ period }: { period: string }) => {
    setExporting(true);
    const measurements: string[] = [];
    const fields: string[] = [];
    if (formData['toggle_ground']) {
      measurements.push('Continuitytest');
      fields.push('Resistance');
    }

    if (formData['toggle_wear']) {
      measurements.push('Wear');
      if (!fields.includes('Resistance')) {
        fields.push('Resistance');
      }
    }

    if (formData['toggle_power']) {
      measurements.push('Energy');
      fields.push('Count');
    }

    if (formData['toggle_trend']) {
      if (!measurements.includes('Wear')) {
        measurements.push('Wear');
      }
      fields.push('Trend');
    }

    const medianQuery = influxExportQuery({
      ovens: [Number.parseInt(formData['oven']) + 1],
      measurements,
      fields,
      start: formData['date-start'],
      end: formData['date-end'],
      period,
      aggregation: 'median',
      bucket: influxBucket,
    });

    const minQuery = influxExportQuery({
      ovens: [Number.parseInt(formData['oven']) + 1],
      measurements,
      fields,
      start: formData['date-start'],
      end: formData['date-end'],
      period,
      aggregation: 'min',
      bucket: influxBucket,
    });

    const maxQuery = influxExportQuery({
      ovens: [Number.parseInt(formData['oven']) + 1],
      measurements,
      fields,
      start: formData['date-start'],
      end: formData['date-end'],
      period,
      aggregation: 'max',
      bucket: influxBucket,
    });

    queryRows({
      api: influxQueryApi,
      query: medianQuery,
      setter: setMedianData,
      isExport: true,
      setExporting,
    });
    queryRows({
      api: influxQueryApi,
      query: minQuery,
      setter: setMinData,
      isExport: true,
      setExporting,
    });
    queryRows({
      api: influxQueryApi,
      query: maxQuery,
      setter: setMaxData,
      isExport: true,
      setExporting,
    });

    return new Promise((resolve) => resolve(true));
  };

  let wearTendLabel = '';
  const wearTrendData: AnalysisDataSeries[] = [];
  if (formData['toggle_wear']) {
    wearTrendData.push({
      name: 'wear',
      color: '#007C8F',
      datapoints: wearData,
    });
    wearTendLabel = 'Wear';
  }

  if (formData['toggle_trend']) {
    wearTrendData.push({
      name: 'trend',
      color: '#980053',
      datapoints: trendData,
    });
    if (wearTendLabel.length === 0) {
      wearTendLabel = 'Trend';
    } else {
      wearTendLabel += ' / Trend';
    }
  }

  const OvenJourneySelect = () => {
    return (
      <div className={'row justify-content-center'}>
        <div className={'col-2 d-flex justify-content-end'}>
          {hasPrevJourney() && (
            <div className={''}>
              <span
                className={'cursor-pointer'}
                onClick={() => selectPreviousJourney()}
              >
                <BiChevronLeft /> <span className={'fs-caption'}>frühere</span>
              </span>
            </div>
          )}
        </div>
        <div className={'col-auto'}>
          {isLoading ? (
            <Spinner animation="grow" variant={'primary'} />
          ) : selectedOvenJourney ? (
            selectedOvenJourney.attributes.name
          ) : results && results.length > 0 ? (
            ''
          ) : (
            'Keine Ofenreisen vorhanden'
          )}
        </div>
        <div className={'col-2'}>
          {selectedOvenJourney && hasNextJourney() && (
            <span
              className={'cursor-pointer'}
              onClick={() => selectNextJourney()}
            >
              <span className={'fs-caption'}>spätere</span> <BiChevronRight />
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div ref={ref} className="history">
      <div className="fs-2">History</div>
      <div style={{ height: `${size.height / 24}px` }}></div>
      <div className={'card shadow p-4 w-100'}>
        <div className={'row align-content-center'}>
          <div className={'col-2 fs-3'}>
            <DropdownButton
              variant="secondary"
              id={`oven-toggle`}
              disabled={false}
              title={ovenLabel}
            >
              {ovens.map((oven: number) => (
                <Dropdown.Item
                  key={`oven-toggle-${oven}`}
                  id={'oven'}
                  eventKey={oven}
                  onClick={onChange}
                >
                  Ofen {oven + 1}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
          <div className={'col-6 align-self-center'}>
            <OvenJourneySelect />
          </div>
          {/*CSV Dropdown*/}
          <div className={'col-2 align-self-center'}>
            <DropdownButton
              variant="secondary"
              id={`export-dropdown`}
              disabled={false}
              title={'Als CSV exportieren'}
            >
              <div
                className={'export-item'}
                onClick={() => {
                  setCsvRef(csv1SecRef);
                  prepareCsvData({ period: '1s' });
                }}
              >
                Unkomprimiert
              </div>
              <div
                className={'export-item'}
                onClick={() => {
                  setCsvRef(csv1MinRef);
                  prepareCsvData({ period: '1m' });
                }}
              >
                1 Minuten komprimiert
              </div>
              <div
                className={'export-item'}
                onClick={() => {
                  setCsvRef(csv5MinRef);
                  prepareCsvData({ period: '15m' });
                }}
              >
                15 Minuten komprimiert
              </div>
            </DropdownButton>
            <div className={'d-hidden'}>
              <CSVLink
                data={exportData}
                filename={`ews-export-1-second-${new Date(Date.now())
                  .toISOString()
                  .substring(0, 10)}.csv`}
                ref={csv1SecRef}
              ></CSVLink>
              <CSVLink
                data={exportData}
                filename={`ews-export-1-minute-${new Date(Date.now())
                  .toISOString()
                  .substring(0, 10)}.csv`}
                ref={csv1MinRef}
              ></CSVLink>
              <CSVLink
                data={exportData}
                filename={`ews-export-15-minutes-${new Date(Date.now())
                  .toISOString()
                  .substring(0, 10)}.csv`}
                ref={csv5MinRef}
              ></CSVLink>
            </div>
          </div>
          <div className={'col-2 align-self-center'}>
            <Button
              variant={'secondary'}
              onClick={() => onDeleteJourney()}
              disabled={!selectedOvenJourney}
            >
              <FaTrash /> Ofenreise löschen
            </Button>
          </div>
        </div>
        <div className={'row'}>
          {/*Ofenauswahl*/}
          {/*Ofenreisen beginnen/beenden*/}
          <div className={'col-12'}>
            <div className={'row justify-content-end'}>
              {/*Toggles*/}
              <div className={'col-8 align-self-center'}></div>
              <div className={'col-2'}>
                <Form.Label className={'fs-caption'}>Anfang</Form.Label>
                <DatePicker
                  locale="de-DE"
                  dateFormat={dateTimeFormatString}
                  selected={
                    formData['date-start']
                      ? new Date(
                          Number.parseInt(formData['date-start'] as string) *
                            1000,
                        )
                      : undefined
                  }
                  onChange={(event) =>
                    onDateChange({ date: event, property: 'date-start' })
                  }
                  showTimeInput
                />
              </div>
              <div className={'col-2'}>
                <Form.Label className={'fs-caption'}>Ende</Form.Label>
                <DatePicker
                  locale="de-DE"
                  dateFormat={dateTimeFormatString}
                  selected={
                    formData['date-end']
                      ? new Date(
                          Number.parseInt(formData['date-end'] as string) *
                            1000,
                        )
                      : undefined
                  }
                  onChange={(event) =>
                    onDateChange({ date: event, property: 'date-end' })
                  }
                  showTimeInput
                />
              </div>
            </div>
            <div className={'row justify-content-end'}>
              {/*Toggles*/}
              <div className={'col-8 align-self-start'}>
                <div className={'row align-content-center'}>
                  <div className={'col-2'}></div>
                  <div className={'col-2'}>
                    <div className={'d-flex justify-content-end'}>
                      <div className="form-check form-switch">
                        <label
                          className="form-check-label"
                          htmlFor="toggle_wear"
                        >
                          <span className={'color-primary'}>Wear</span>
                        </label>
                        <input
                          className="form-check-input"
                          readOnly={false}
                          disabled={false}
                          type="checkbox"
                          role="switch"
                          id="toggle_wear"
                          name={'wear'}
                          onChange={onToggle}
                          checked={formData['toggle_wear']}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={'col-2'}>
                    <div className={'d-flex justify-content-end'}>
                      <div className="form-check form-switch">
                        <label
                          className="form-check-label"
                          htmlFor="toggle_trend"
                        >
                          <span className={'color-primary-highlight'}>
                            Trend
                          </span>
                        </label>
                        <input
                          className="form-check-input primary-hightlight-button"
                          readOnly={false}
                          disabled={false}
                          type="checkbox"
                          role="switch"
                          id="toggle_trend"
                          name={'wear'}
                          onChange={onToggle}
                          checked={formData['toggle_trend']}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={'col-2'}>
                    <div className={'d-flex justify-content-end'}>
                      <div className="form-check form-switch">
                        <label
                          className="form-check-label"
                          htmlFor="toggle_ground"
                        >
                          <span className={'color-yellow'}>Continuity</span>
                        </label>
                        <input
                          className="form-check-input yellowButton"
                          readOnly={false}
                          disabled={false}
                          type="checkbox"
                          role="switch"
                          id="toggle_ground"
                          name={'melt'}
                          onChange={onToggle}
                          checked={formData['toggle_ground']}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={'col-2'}>
                    <div className={'d-flex justify-content-end'}>
                      <div className="form-check form-switch">
                        <label
                          className="form-check-label"
                          htmlFor="toggle_power"
                        >
                          <span className={'color-green'}>Power</span>
                        </label>
                        <input
                          className="form-check-input greenButton"
                          readOnly={false}
                          disabled={false}
                          type="checkbox"
                          role="switch"
                          id="toggle_power"
                          name={'power'}
                          onChange={onToggle}
                          checked={formData['toggle_power']}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={'col-2'}></div>
                </div>
              </div>
              <div className={'col-2'}>
                {!isOvenJourneyRunning() && (
                  <Button
                    className={'mt-1 p-1'}
                    onClick={() =>
                      saveOvenJourney({
                        isRunning: isOvenJourneyRunning(),
                      })
                    }
                  >
                    Neue Ofenreise hier beginnen
                  </Button>
                )}
              </div>
              <div className={'col-2'}>
                {isOvenJourneyRunning() && (
                  <Button
                    className={'mt-1 p-1'}
                    onClick={() =>
                      saveOvenJourney({
                        isRunning: isOvenJourneyRunning(),
                      })
                    }
                  >
                    Aktive Ofenreise hier beenden
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        <hr />
        {/*Graphs*/}
        {(formData['toggle_wear'] || formData['toggle_trend']) && (
          <div className={'pt-4'}>
            <AnalysisChart
              data={wearTrendData}
              start={formData['date-start']}
              end={formData['date-end']}
              warning={ovenMeltWarning}
              critical={ovenMeltCritical}
              width={graphWidth}
              label={wearTendLabel}
              unit={'Ω'}
              isLoading={isTrendLoading || isWearLoading}
            />
            <hr />
          </div>
        )}

        {formData['toggle_ground'] && (
          <div className={'pt-4'}>
            <AnalysisChart
              data={[
                { name: 'ground', color: '#B4A200', datapoints: groundData },
              ]}
              start={formData['date-start']}
              end={formData['date-end']}
              warning={ovenGroundWarning}
              critical={ovenGroundCritical}
              width={graphWidth}
              label={'Continuity'}
              unit={'Ω'}
              isLoading={isContinuityLoading}
            />
            <hr />
          </div>
        )}

        {formData['toggle_power'] && (
          <div className={'pt-4'}>
            <AnalysisChart
              data={[
                { name: 'power', color: '#52A855', datapoints: powerData },
              ]}
              start={formData['date-start']}
              end={formData['date-end']}
              warning={200}
              critical={400}
              width={graphWidth}
              label={'Power'}
              unit={'W'}
              isLoading={isPowerLoading}
            />
          </div>
        )}
      </div>
      {isExporting && (
        <div className={'exporting-overlay'}>
          <div className={'pb-4 fs-4'}>Daten werden exportiert...</div>
          <Spinner animation="grow" variant={'primary'} />
        </div>
      )}
    </div>
  );
}
