import * as d3 from 'd3';
import { timeFormat } from 'd3-time-format';

d3.timeFormatDefaultLocale({
  dateTime: '%A, der %e. %B %Y, %X',
  date: '%d.%m.%Y',
  time: '%H:%M:%S',
  periods: ['AM', 'PM'],
  days: [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
  ],
  shortDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  months: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
  shortMonths: [
    'Jan',
    'Feb',
    'Mrz',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dez',
  ],
});

export const dateTimeFormatString = 'dd.MM.yyyy HH:mm';

export const formatTime = timeFormat('%X');
export const formatTimeAndDate = timeFormat('%d.%m.%y %H:%M');
export const formatTimeWithSecondsAndDate = timeFormat('%d.%m.%y %H:%M:%S');
export const formatTimeAndDateForFilename = timeFormat('%d-%m-%y_%H%M');
export const formatWithFullDateAt0 = (x: Date) => {
  if ((x as Date).getHours() === 0 && (x as Date).getMinutes() === 0) {
    return formatTimeAndDate(x);
  }
  return formatTime(x);
};

export const formatForInput = ({
  millis,
  isEnd,
}: {
  millis: number;
  isEnd?: boolean;
}): string | undefined => {
  if (!millis) {
    return undefined;
  }

  const dayString = new Date(millis).toISOString().substring(0, 10);
  let dayMillis = Date.parse(dayString);
  if (isEnd) {
    dayMillis += 85399;
  }
  return new Date(dayMillis).toISOString().substring(0, 10);
};
