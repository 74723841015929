import { ValidationMap } from 'prop-types';

import { CustomParseObject } from '../../models/parse-objects';
import { useParse } from '../../providers/parse-provider/parse-provider';

const Parse = useParse();

export interface SettingsState {
  settingsByCategory: SettingsMap;
  settings: SettingType[];
  status: string;
  error?: string | null;
}

export class Setting extends Parse.Object {
  constructor({
    name,
    label,
    caption,
    valueWithType,
    category,
    options,
    sortOrder,
    source,
  }: SettingType) {
    // Pass the ClassName to the Parse.Object constructor
    super(CustomParseObject.Setting);
    // All other initialization
    this.set('name', name);
    this.set('label', label);
    this.set('caption', caption);
    this.set('valueWithType', valueWithType);
    this.set('category', category);
    this.set('options', options);
    this.set('sortOrder', sortOrder);
    this.set('source', source);
  }

  toBean() {
    return {
      id: this.id,
      name: this.attributes.name,
      label: this.attributes.label,
      caption: this.attributes.caption,
      valueWithType: this.attributes.valueWithType,
      category: this.attributes.category,
      options: this.attributes.options,
      sortOrder: this.attributes.sortOrder,
    };
  }
}

export interface SettingType {
  id?: ValidationMap<string>;
  name: string;
  label?: string;
  caption?: string;
  valueWithType: ValueWithType;
  category: string;
  options?: number[] | string[];
  sortOrder?: number;
  source: SettingSource;
}

export interface SettingsMap {
  [key: string | keyof typeof SystemSettings]: SettingType[];
}

export interface ValueWithType {
  value: unknown;
  type: SettingValueType;
  unit?: string;
}

export enum SettingValueType {
  'boolean' = 'boolean',
  'string' = 'string',
  'stringList' = 'string-list',
  'number' = 'number',
  'numberList' = 'number-list',
  'date' = 'date',
}

export enum SystemSettings {
  ovens = 'ovens',
  showMaintenanceWarning = 'showMaintenanceWarning',
  maintenanceWarningText = 'maintenanceWarningText',
  licenceValidUntil = 'licenceValidUntil',
  licenceWarningText = 'licenceWarningText',
  daysBeforeLicenceEndsWarning = 'daysBeforeLicenceEndsWarning',
}

export enum SettingSource {
  'api' = 'api',
  'parse' = 'parse',
}
