import './_sidebar.scss';

import React, { FunctionComponent, useState } from 'react';
import { BiLogOut } from 'react-icons/bi';
import { NavLink } from 'react-router-dom';

import { useAuth } from '../../providers/auth-provider/auth-provider';
import { ReactComponent as IconHistory } from './../../assets/icon-history.svg';
import { ReactComponent as IconLive } from './../../assets/icon-live.svg';
import { ReactComponent as IconSettings } from './../../assets/icon-settings.svg';

export type SidebarProps = {
  open?: boolean;
};

export const Sidebar: FunctionComponent<SidebarProps> = () => {
  const [_currentPage, setCurrentPage] = useState<string>('live');
  const { onLogout, user } = useAuth();

  const iconSize = '32px';

  return (
    <div className="sidebar p-2">
      <div className={'pt-5 pb-5'}></div>
      <div className="icons">
        <nav>
          {user && (
            <>
              <NavLink to="/live" onClick={() => setCurrentPage('live')}>
                <IconLive
                  stroke="currentColor"
                  className={`icon ${_currentPage === 'live' ? 'active' : ''}`}
                />
              </NavLink>
              <NavLink to="/history" onClick={() => setCurrentPage('history')}>
                <IconHistory
                  stroke="currentColor"
                  className={`icon ${
                    _currentPage === 'history' ? 'active' : ''
                  }`}
                />
              </NavLink>
              <NavLink
                to="/settings"
                onClick={() => setCurrentPage('settings')}
              >
                <IconSettings
                  stroke="currentColor"
                  className={`icon ${
                    _currentPage === 'settings' ? 'active' : ''
                  }`}
                />
              </NavLink>
            </>
          )}
          <div style={{ flexGrow: 1 }}>&nbsp;</div>
          {user && (
            <NavLink to="/login" onClick={() => setCurrentPage('login')}>
              <BiLogOut
                className="icon logout"
                size={iconSize}
                onClick={onLogout}
              />
            </NavLink>
          )}
        </nav>
      </div>
    </div>
  );
};
