import { useEffect, useRef } from 'react';

import { resizeCanvas } from './canvas-helper';

const useCanvas = (
  draw: (ctx: CanvasRenderingContext2D, frameCount: number) => void,
  options: { height?: number; context?: string },
) => {
  const canvasRef = useRef<HTMLCanvasElement>();

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext(
      options.context || '2d',
    ) as CanvasRenderingContext2D;
    let frameCount = 0;
    let animationFrameId: number;

    if (!context) {
      return;
    }

    const render = () => {
      frameCount++;

      if (!canvas?.parentElement) {
        return;
      }

      predraw(context, {
        width: canvas?.parentElement.offsetWidth
          ? canvas?.parentElement.offsetWidth
          : 300,
        height: options.height ? options.height : 150,
      });
      draw(context, frameCount);
      postdraw(context);
      animationFrameId = window.requestAnimationFrame(render);
    };
    render();

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [draw]);

  return canvasRef;
};

export default useCanvas;

export const predraw = (
  context: CanvasRenderingContext2D | null | undefined,
  targetSize: { width?: number; height?: number },
) => {
  if (!context) {
    return;
  }
  context.save();
  resizeCanvas(context.canvas, targetSize);
  const { width, height } = context.canvas;
  context.clearRect(0, 0, width, height);
};

const postdraw = (ctx: CanvasRenderingContext2D | null | undefined) => {
  if (!ctx) {
    return;
  }
  ctx.restore();
};
