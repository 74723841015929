import { Defs } from '@nivo/core';
import { Layer, Line } from '@nivo/line';
import { area, curveMonotoneX } from 'd3-shape';
import { maxBy } from 'lodash';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { nFormatter } from '../../helpers/number-helpers';
import { DataPoint } from '../oven-live/oven-live';

// eslint-disable-next-line @typescript-eslint/ban-types
export type RealtimeLineChartProps = {
  critical?: unknown;
  warning?: unknown;
  curveColor: string;
  unitY?: string;
  height?: number;
  data: DataPoint[];
  interval?: number;
};

const WARNING_AREA_COLOR = '#FFDE03';
const ERROR_AREA_COLOR = '#B00020';

// eslint-disable-next-line no-empty-pattern
export default function RealtimeLineChart({
  warning = 0,
  critical = 0,
  curveColor,
  unitY,
  data,
  interval = 30,
}: RealtimeLineChartProps) {
  const date = new Date();
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  if (typeof critical === 'string') {
    critical = Number.parseInt(critical);
  }

  if (typeof warning === 'string') {
    warning = Number.parseInt(warning);
  }

  const [showNoData, setShowNoData] = useState<boolean>(false);
  let noDataTimer: string | number | NodeJS.Timeout | undefined;
  const ref = useRef<HTMLDivElement>(null);
  const [minMaxY, setMinMaxY] = useState<[number, number]>([0, 1000]);
  const minMaxRef = useRef<[number, number]>([0, 1000]);

  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  useLayoutEffect(() => {
    if (ref.current?.parentElement?.offsetWidth) {
      setWidth(ref.current.parentElement.offsetWidth);
    }
    if (ref.current?.parentElement?.offsetHeight) {
      setHeight(
        ref.current?.parentElement?.parentElement?.parentElement
          ?.offsetHeight || 125,
      );
    }
  }, [
    ref.current?.parentElement?.offsetWidth,
    ref.current?.parentElement?.offsetHeight,
  ]);

  /// EFFECTS
  useEffect(() => {
    noDataTimer = setTimeout(showNoDataTimout, 5000);

    return () => {
      clearTimeout(noDataTimer);
    };
  }, []);

  useEffect(() => {
    if (data.length > 0 && noDataTimer) {
      clearTimeout(noDataTimer);
      setShowNoData(false);
    }

    const maxYValue = maxBy(data, (o) => o.y)?.y || 1000;
    if (!minMaxRef.current || maxYValue != minMaxRef.current[1]) {
      minMaxRef.current = [0, maxYValue];
      setMinMaxY([0, maxYValue]);
    }
  }, [data]);

  const showNoDataTimout = () => {
    setShowNoData(true);
  };

  const warningLower =
    !warning || (warning as number) === 0
      ? 0
      : (warning as number) < (critical as number)
        ? warning
        : critical;
  const warningUpper =
    !warning || (warning as number) === 0
      ? 0
      : (warning as number) < (critical as number)
        ? (critical as number)
        : (warning as number);

  const criticalLower =
    !critical || (critical as number) === 0
      ? 0
      : (warning as number) < (critical as number)
        ? (critical as number)
        : 0;
  const criticalUpper =
    !critical || (critical as number) === 0
      ? 0
      : (warning as number) < (critical as number)
        ? 1000000
        : (critical as number);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const WarningLayer = ({ series, xScale, yScale }: any) => {
    if (!warning || warning === 0) {
      return null;
    }
    const areaGenerator = area()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .x((d: any) => xScale(d.data.x))
      .y0(() => yScale(warningLower))
      .y1(() => yScale(warningUpper))
      .curve(curveMonotoneX);

    const generatedArea = areaGenerator(series[0].data);
    if (!generatedArea) {
      return <></>;
    }

    return (
      <>
        <Defs
          defs={[
            {
              id: 'pattern',
              type: 'patternLines',
              background: 'transparent',
              color: WARNING_AREA_COLOR,
              lineWidth: 1,
              spacing: 6,
              rotation: -45,
            },
          ]}
        />
        <path
          d={generatedArea || undefined}
          fill={WARNING_AREA_COLOR}
          fillOpacity={0.2}
          stroke={WARNING_AREA_COLOR}
          strokeWidth={0}
        />
      </>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CriticalLayer = ({ series, xScale, yScale }: any) => {
    if (!critical || critical === 0) {
      return null;
    }

    const areaGenerator = area()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .x((d: any) => xScale(d.data.x))
      .y0(() => yScale(criticalLower))
      .y1(() => yScale(criticalUpper))
      .curve(curveMonotoneX);

    const generatedArea = areaGenerator(series[0].data);
    if (!generatedArea) {
      return <></>;
    }

    return (
      <>
        <Defs
          defs={[
            {
              id: 'pattern',
              type: 'patternLines',
              background: 'transparent',
              color: ERROR_AREA_COLOR,
              lineWidth: 1,
              spacing: 6,
              rotation: -45,
            },
          ]}
        />
        <path
          d={generatedArea || undefined}
          fill={ERROR_AREA_COLOR}
          fillOpacity={0.2}
          stroke={ERROR_AREA_COLOR}
          strokeWidth={0}
        />
      </>
    );
  };

  const layers: Layer[] = ['grid', 'areas', 'lines', 'axes', 'legends'];

  if (warning && (warning as number) > 0) {
    layers.push(WarningLayer);
  }
  if (critical && (critical as number) > 0) {
    layers.push(CriticalLayer);
  }

  return (
    <div ref={ref} className="realtime-line-chart">
      {/* eslint-disable-next-line react/prop-types */}
      {data.length === 0 && showNoData && <div>Keine Daten verfügbar</div>}
      {data.length > 0 && width && (
        <Line
          width={width}
          height={height}
          margin={{ top: 10, right: 0, bottom: 30, left: 40 }}
          data={[
            {
              id: 'id',
              color: curveColor,
              data,
            },
          ]}
          colors={curveColor || 'hsl(0, 0%, 0%)'}
          xScale={{ type: 'time', format: 'native' }}
          yScale={{ type: 'linear', max: minMaxY[1] * 1.3, clamp: true }}
          axisTop={null}
          axisRight={null}
          axisLeft={{
            format: (value) => nFormatter(value, 1, unitY),
            tickValues: 2,
          }}
          axisBottom={{
            format: '%H:%M',
            tickValues: `every ${interval / 5} minutes`,
          }}
          enablePoints={false}
          enableGridX={true}
          curve="linear"
          animate={false}
          isInteractive={false}
          enableSlices={false}
          useMesh={true}
          theme={{
            axis: { ticks: { text: { fontSize: 10 } } },
            grid: { line: { stroke: '#ddd', strokeDasharray: '1 2' } },
          }}
          enableArea={false}
          layers={layers}
        />
      )}
    </div>
  );
}
