import React, { FunctionComponent, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { Role, useAuth } from '../../providers/auth-provider/auth-provider';

export type ProtectedComponentProps = {
  requiredRoles: Role[];
  children: React.ReactElement;
};

export const ProtectedComponent: FunctionComponent<ProtectedComponentProps> = ({
  requiredRoles,
  children,
}: ProtectedComponentProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, hasRole, sessionLogin } = useAuth();
  const [hasAccess, setHasAccess] = useState<boolean>(false);
  const [initDone, setInitDone] = useState<boolean>(false);
  let isSessionLogin = false;

  useEffect(() => {
    if (requiredRoles.length === 0) {
      setHasAccess(true);
      setInitDone(true);
    }

    hasRole(requiredRoles).then((hasIt) => {
      setHasAccess(hasIt);
      setInitDone(true);
    });
  }, [location.pathname]);

  if (!user && location.search && location.search.length > 0) {
    const pairs = location.search.substring(1).split('&');
    const paramMap: { [key: string]: string } = {};
    for (const pair of pairs) {
      const keyValue = pair.split('=');
      paramMap[keyValue[0]] = keyValue[1];
    }

    if (Object.keys(paramMap).includes('token')) {
      isSessionLogin = true;
      sessionLogin({ token: paramMap['token'] }).then(() => {
        navigate('/live');
      });
    }
  }

  if (isSessionLogin) {
    return <Spinner animation="grow" variant={'primary'} />;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (!initDone) {
    return <></>;
  }

  const locationLabel = (pathname: string): string => {
    if (!pathname || pathname.length < 2) {
      return 'für diese Seite.';
    }

    return pathname.charAt(1).toUpperCase() + pathname.slice(2);
  };

  if (hasAccess) {
    return children;
  } else {
    return (
      <div className={'d-flex text-center justify-content-center h-'}>
        {user.getUsername()} hat keine Zugriffsberechtigung für{' '}
        {locationLabel(location.pathname)}
      </div>
    );
  }
};
