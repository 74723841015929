import './../../common.scss';
import './_login-page.scss';

import React, { useReducer, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as IconWarning } from '../../assets/road-sign-warning.svg';
import { formatTimeAndDate } from '../../helpers/date-helpers';
import { useAuth } from '../../providers/auth-provider/auth-provider';
import { ReactComponent as IconEmailUnread } from './../../assets/email-action-unread.svg';
import synantik_logo from './../../assets/logo-ews.png';
import { ReactComponent as IconPasswordApproved } from './../../assets/password-approved.svg';
import pulse from './../../assets/pulse.svg';

type LoginPageState = {
  username: string;
  password: string;
};

const formReducer = (
  state: LoginPageState,
  event: { target: { value: string | number; name: string } },
) => {
  return {
    ...state,
    [event.target.name]: event.target.value,
  };
};

export type LoginPageProps = {
  submitting?: boolean;
  error?: string;
};

export const LoginPage: React.FC<LoginPageProps> = ({
  submitting,
  error,
}: LoginPageProps) => {
  const [formData, setFormData] = useReducer(formReducer, {
    username: '',
    password: '',
  });
  const [_submitting, setSubmitting] = useState<boolean>(submitting || false);
  const [_error, setError] = useState<string | null>(error || null);
  const { onLogin } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setError(null);
    setSubmitting(true);
    onLogin(formData)
      .then(() => {
        navigate('/live');
      }) // wait for async function
      .catch((error: string) => setError(error)) // handle error
      .finally(() => setSubmitting(false)); // cleanup
  };

  return (
    <div
      className="login-page"
      style={{
        backgroundImage: `url(${pulse})`,
        backgroundPosition: `left 0% bottom 50%`,
        backgroundRepeat: `repeat-x`,
        backgroundSize: `300px`,
      }}
    >
      <div className="header">
        <img src={synantik_logo} alt="EWS" width="250px" />
      </div>

      <div className="card shadow p-4 mb-5 bg-body rounded login-form">
        <div className="text-start text-left mb-4">
          <span className="fs-2">Login</span>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="text-center">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <IconEmailUnread viewBox="8 -8 16 48" />
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Benutzername"
                aria-label="Benutzername"
                aria-describedby="input-username"
                name="username"
                onChange={setFormData}
              />
            </div>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <IconPasswordApproved viewBox="8 -8 16 48" />
              </div>
              <input
                type="password"
                className="form-control"
                placeholder="Passwort"
                aria-label="Passwort"
                aria-describedby="basic-addon2"
                name="password"
                onChange={setFormData}
              />
            </div>
            {_submitting && (
              <div className="spinner-border text-primary mt-4" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
            {!_submitting && (
              <button type="submit" className="btn btn-primary w-100 mt-4">
                Einloggen
              </button>
            )}
          </div>
        </form>
      </div>
      {_error && (
        <>
          <Alert
            key={'login-error'}
            className={'alert shadow'}
            variant={'light'}
            dismissible={false}
            closeLabel={'Bestätigen'}
            // onClose={() => onAcknowledge(error)}
          >
            <div className={'d-flex row'}>
              <div className={'col-2'}>
                <IconWarning stroke="currentColor" />
              </div>
              <div className={'col-10'}>
                <div className={'row'}>
                  <div className={'col-12'}>
                    <div className={'row'}>
                      <div className={'col-9 fs-4 col color-alert'}>Fehler</div>
                      <div className={'col-3 pt-1 fs-caption text-end'}>
                        {formatTimeAndDate(new Date(Date.now()))}
                      </div>
                    </div>
                    <div className={'row'}>
                      <div className={'col-12'}>{_error.toString()}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Alert>
        </>
      )}
      {!_error && (
        <div className="card p-4">
          <span className="">&nbsp;</span>
        </div>
      )}
    </div>
  );
};
