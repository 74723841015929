import { initializeParse } from '@parse/react';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Parse = require('parse');
import env from 'react-dotenv';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const javascriptKey = (window as any).JAVASCRIPT_KEY;
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const appId = (window as any).APP_ID;
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const publicServerUrl = (window as any).PUBLIC_SERVER_URL;

initializeParse(
  publicServerUrl || env?.REACT_APP_SERVER_URL,
  appId || env?.REACT_APP_APP_ID,
  javascriptKey || env?.REACT_APP_JAVASCRIPT_KEY,
);

Parse.liveQueryServerURL = `${
  window.location.protocol.startsWith('https') ? 'wss' : 'ws'
}://${window.location.host}${
  window.location.port ? ':' + window.location.port : ''
}/parse`;

// Our authentication object. Can be called from anywhere in the app without the need for passing AuthContextProps down the component tree.
export const useParse = () => {
  return Parse;
};
