export function resizeCanvasToDisplaySize(canvas: HTMLCanvasElement) {
  const { width, height } = canvas.getBoundingClientRect();

  if (canvas.width !== width || canvas.height !== height) {
    canvas.width = width;
    canvas.height = height;
    return true; // here you can return some usefull information like delta width and delta height instead of just true
    // this information can be used in the next redraw...
  }

  return false;
}

export function resizeCanvas(
  canvas: HTMLCanvasElement,
  targetSize: { width?: number; height?: number },
) {
  if (!targetSize.width || !targetSize.height) {
    return false;
  }

  const ratio = window.devicePixelRatio;

  if (
    canvas.width / ratio !== targetSize.width ||
    canvas.height / ratio !== targetSize.height
  ) {
    canvas.style.width = targetSize.width + 'px';
    canvas.style.height = targetSize.height + 'px';
    canvas.width = targetSize.width * ratio;
    canvas.height = targetSize.height * ratio;
    // canvas.getContext('2d').scale(ratio / 2, ratio / 2);
    return true;
  }

  return false;
}
