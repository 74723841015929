import './../../common.scss';
import './_user-item.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { CgMoreVerticalO } from 'react-icons/cg';

import { Role } from '../../providers/auth-provider/auth-provider';
import { User } from '../user-list/user-list';

const formReducer = (
  state: { [key: string]: string | number | Date },
  event: { target: { name: string; value: string | number } },
) => {
  return {
    ...state,
    [event.target.name]: event.target.value,
  };
};

export type UserItemProps = {
  user: User;
  createUser?: (user: User) => void;
  saveUser?: (user: User) => void;
  deleteUser: (user: User) => void;
};

export default function UserItem({
  user,
  saveUser,
  deleteUser,
}: UserItemProps) {
  const [formData, setFormData] = useReducer(formReducer, {});
  const [_user, setUser] = useState<User>(user);
  const [_showResetPassword, setShowResetPassword] = useState<boolean>(false);
  const [_showResetUsername, setShowResetUsername] = useState<boolean>(false);
  const initialRender = useRef(0);

  useEffect(() => {
    if (initialRender.current < 1) {
      initialRender.current += 1;
    } else {
      save();
    }
  }, [_user]);

  const save = () => {
    if (_user.id) {
      if (typeof saveUser === 'function') {
        saveUser(_user);
      }
    }
  };

  const handleRoleChange = ({
    target: { name, checked },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const nextUser = Object.assign({}, _user, {
      roles: [...(user.roles || [])],
    });

    const roleName = name as keyof typeof Role;

    if (checked && !nextUser.roles.includes(Role[roleName])) {
      const role = Role[roleName];
      nextUser.roles.push(role);
    }

    if (!checked && nextUser.roles.includes(Role[roleName])) {
      nextUser.roles.splice(nextUser.roles.indexOf(Role[roleName]), 1);
    }

    setUser(nextUser);
  };

  const handleShowResetPassword = () => {
    setShowResetPassword(true);
  };

  const handleCancelResetPassword = () => {
    setShowResetPassword(false);
  };

  const handleSaveResetPassword = () => {
    const nextUser = Object.assign({}, _user, formData);
    setUser(nextUser);
    setShowResetPassword(false);
  };

  const handleShowResetUsername = () => {
    setShowResetUsername(true);
  };

  const handleCancelResetUsername = () => {
    setShowResetUsername(false);
  };

  const handleSaveResetUsername = () => {
    const nextUser = Object.assign({}, _user, formData);
    setUser(nextUser);
    setShowResetUsername(false);
  };

  return (
    <>
      <tr key={_user.id} className="user-item">
        <td>
          <input
            type="text"
            className="form-control"
            readOnly
            disabled
            placeholder=""
            aria-label="Benutzer"
            aria-describedby="basic-addon1"
            value={_user.username}
            name="username"
          />
        </td>
        {(Object.keys(Role) as Array<keyof typeof Role>).map((key) => (
          <td key={_user.id + '-' + key}>
            <div className="form-check form-switch pt-2">
              <input
                className="form-check-input"
                readOnly={_user.username === 'admin'}
                disabled={_user.username === 'admin'}
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                name={key}
                onChange={handleRoleChange}
                checked={_user?.roles?.includes(Role[key])}
              />
            </div>
          </td>
        ))}
        <td>
          {_user.updatedAt && (
            <input
              type="datetime-local"
              className="form-control"
              placeholder=""
              aria-label="Aktualisiert am"
              aria-describedby="basic-addon1"
              readOnly
              disabled
              defaultValue={_user.updatedAt?.substring(0, 16)}
              name="updatedAt"
            />
          )}
        </td>
        <td className="text-center">
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
              <CgMoreVerticalO size={32} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={handleShowResetPassword}>
                Passwort zurücksetzen
              </Dropdown.Item>
              {_user.username != 'admin' && (
                <Dropdown.Item onClick={handleShowResetUsername}>
                  Nutzer umbenennen
                </Dropdown.Item>
              )}
              {_user.username != 'admin' && (
                <Dropdown.Item onClick={() => deleteUser(_user)}>
                  Nutzer löschen
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
      <Modal show={_showResetPassword} onHide={handleCancelResetPassword}>
        <Modal.Header closeButton>
          <Modal.Title>Neues Passwort</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>
            Neues Passwort
            <input
              type="password"
              className="form-control"
              placeholder=""
              aria-label="Passwort"
              aria-describedby="basic-addon1"
              defaultValue={_user.password}
              onChange={setFormData}
              name="password"
              autoComplete="new-password"
            />
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelResetPassword}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={handleSaveResetPassword}>
            Speichern
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={_showResetUsername} onHide={handleCancelResetUsername}>
        <Modal.Header closeButton>
          <Modal.Title>Neuer Nutzername</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>
            Neuer Benutzername
            <input
              type="text"
              className="form-control"
              placeholder=""
              aria-label="Benutzername"
              aria-describedby="basic-addon1"
              defaultValue={_user.password}
              onChange={setFormData}
              name="username"
              autoComplete="off"
            />
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelResetUsername}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={handleSaveResetUsername}>
            Speichern
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
// eslint-disable-next-line react/display-name
const CustomToggle = React.forwardRef<HTMLAnchorElement>(
  // eslint-disable-next-line
  ({ children, onClick }: any, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ),
);

UserItem.propTypes = {
  user: PropTypes.object,
  createUser: PropTypes.func,
  saveUser: PropTypes.func,
  deleteUser: PropTypes.func,
};
